import parse from 'html-react-parser';
import { files } from 'arquivos-estaticos';
import {IResources} from './interface-resources'
import { VariaveisAmbiente } from 'ambiente';
import { S2PayTema } from 'temas'


/* eslint-disable no-eval */
const resS2Pay = () => {
  const fileReader = files();
  const getObject = () => {
    return {
      nome: 's2Pay',
      theme: S2PayTema,
      tituloPagina: 's2Pay Auth - Bem Vindo!',
      favIcon: fileReader.loadFavIcon('s2Pay', 'favicon.ico'),
      logo: fileReader.loadLogo('s2Pay', 'logo.png'),
      // Remover ao implementar o upload de gravatar - Vinicius Serafim 28-06-2021
      gravatarTemp: fileReader.loadGravatar('s2Pay', 'icon.png'),
      footer: parse(`s2Pay © - Copyright ${new Date().getFullYear()} - Todos os direitos reservados.` + (VariaveisAmbiente.isDev ? " - DEV" : ""))
    }as IResources;
  };

  return { getObject };
};

export const s2Pay = resS2Pay();
/* eslint-enable no-eval */
