import { makeStyles } from '@material-ui/core';

const footerHeight = 40;

export const useStyles = makeStyles((theme) => ({
  full: {
    flex: '1 1 auto',
    width: '100%',
    minHeight: '100%',
  },
  pnlAcesso: {
    width: '100%',
    height: `calc(100% - ${footerHeight}px)`,
    background: theme.palette.background.paper,
    overflow: 'auto',
    position: 'relative',
  },
  pnlConteudo: {
    top: '0',
    width: '100%',
    position: 'absolute',
    minHeight: `100%`,
    height: `100%`,
    overflow: 'auto',
    paddingBottom: `${footerHeight}px`,
    zIndex: 1,
    background: 'transparent',
  },
  pnlFooter: {
    position: 'fixed',
    bottom: 0,
    left: '0px',
    width: '100%',
    background: theme.palette.primary.dark,
    textAlign: 'center',
    color: theme.palette.primary.contrastText,
    lineHeight: `${footerHeight}px`,
    height: `${footerHeight}px`,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    padding: '0 8px',
    zIndex: 2,
  },
  imgLogo: {
    width: '100%',
    marginTop:'10px',
    objectFit: 'contain',
  },
}));
