import { useFormik } from 'formik';
import { LoginValidacao } from './login-validacao';
import { useLoginService } from 'servicos';
import { useEffect, useCallback } from 'react';
import { useNotificacoes } from 'componentes';
import { useParams } from 'react-router-dom';
import { UserAccounts } from 'utils/users-accounts';
import useState from 'react-usestateref';

export function useLoginLogica() {
  const {
    realizarLogin,
    retornaTokenAtual,
    logarPorContaLogada,
    continuarCadastroSocial,
    carregando,
  } = useLoginService();
  const { showErrorMessage, showSuccessMessage, showWarningMessage } = useNotificacoes();
  const [manterConectado, setManterConectado] = useState(true);

  const { handleSubmit, handleChange, handleBlur, values, errors, touched } = useFormik({
    initialValues: {
      email: '',
      senha: '',
      manterConectado: '',
    },
    validateOnBlur: false,
    validationSchema: LoginValidacao,
    onSubmit: (form) => {
      quandoLoginManual(form.email, form.senha);
    },
  });

  const { parametro } = useParams<{ parametro: string }>();
  useEffect(() => {
    if (Boolean(parametro) && parametro.toString() === 'expirou') {
      showWarningMessage('Sua sessão expirou. Realize o login novamente para continuar.');
    }
  }, [showWarningMessage, parametro]);

  const [token, setarToken] = useState<any>();

  const { getLoggedUsers, deleteUser } = UserAccounts();
  const [contasLogadas, setContasLogadas, contasLogadasRef] = useState([]);

  useEffect(() => {
    carregarContasLogadas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const carregarContasLogadas = () => setContasLogadas(getLoggedUsers());
  const startarLoginSocial = useCallback(
    (props: any) => () => {
      props?.onClick();
    },
    [],
  );

  const quandoLoginContaSalva = useCallback(
    async (conta) => {
      try {
        const { message, sucesso } = await logarPorContaLogada(conta.token, conta.tipo, true);
        if (sucesso === false) {
          showErrorMessage(message);
          deleteUser(conta.id);
          return false;
        } else if (sucesso === true) {
          showSuccessMessage(message);
          setarToken(retornaTokenAtual());
        }
        return true;
      } catch (e) {
        showErrorMessage(e.message);
        return false;
      }
    },
    [
      setarToken,
      logarPorContaLogada,
      retornaTokenAtual,
      showErrorMessage,
      showSuccessMessage,
      deleteUser,
    ],
  );
  const quandoLoginManual = useCallback(
    async (email: string, senha: string) => {
      const { message, sucesso } = await realizarLogin(email, senha, null, null, manterConectado);
      if (sucesso === false) {
        showErrorMessage(message);
      } else if (sucesso === true) {
        showSuccessMessage(message);
      }
      setarToken(retornaTokenAtual());
    },
    [
      setarToken,
      showSuccessMessage,
      showErrorMessage,
      realizarLogin,
      retornaTokenAtual,
      manterConectado,
    ],
  );

  const quandoLoginSocialFalhar = useCallback(
    (redeSocial: string) => () => {
      showErrorMessage(
        `Não foi possível fazer o login com o ${redeSocial}, tente novamente mais tarde.`,
      );
    },
    [showErrorMessage],
  );

  const quandoLoginGoogleForFeito = useCallback(
    async (googleDados: any) => {
      const { message, sucesso } = await realizarLogin('', '', googleDados, null, manterConectado);
      setarToken(retornaTokenAtual());
      if (sucesso === false) {
        showErrorMessage(message);
      } else if (sucesso === true) {
        showSuccessMessage(message);
      }
    },
    [
      setarToken,
      showSuccessMessage,
      showErrorMessage,
      realizarLogin,
      retornaTokenAtual,
      manterConectado,
    ],
  );

  const quandoLoginFacebookForFeito = useCallback(
    async (fbDados: any) => {
      const { message, sucesso } = await realizarLogin('', '', null, fbDados, manterConectado);
      setarToken(retornaTokenAtual());
      if (sucesso === false) {
        showErrorMessage(message);
      } else if (sucesso === true) {
        showSuccessMessage(message);
      }
    },
    [
      setarToken,
      showSuccessMessage,
      showErrorMessage,
      realizarLogin,
      retornaTokenAtual,
      manterConectado,
    ],
  );

  return {
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    carregando,
    continuarCadastroSocial,
    quandoLoginSocialFalhar,
    quandoLoginManual,
    quandoLoginGoogleForFeito,
    quandoLoginFacebookForFeito,
    startarLoginSocial,
    carregarContasLogadas,
    quandoLoginContaSalva,
    setManterConectado,
    manterConectado,
    values,
    token,
    contasLogadas,
    contasLogadasRef,
  };
}
