import { converterTokenGestao, GestaoStorageKeys, useGestaoStorage } from 'utils';
export function usePrivateRoute() {
    const { getRegistro } = useGestaoStorage();
    const tokenUsuario = getRegistro(GestaoStorageKeys.TokenGestao);
    const validaExp = () => {
        let jwt = converterTokenGestao(tokenUsuario || undefined);
        if (!jwt) return false;
        return jwt.exp * 1000 > new Date().getTime();
    };
    const tokenValido = validaExp();
    return { tokenValido };
}