import { useApiGestao } from 'servicos/api-gestao/base';
import { useCallback } from 'react';

export function useAtualizarSenha() {
  const { invocarApi, ...outrasPropriedades } = useApiGestao();

  const atualizarSenha = useCallback(
    async (code: string, email: string, senha: string) => {
      const ret = await invocarApi({
        url: `recuperarsenha/${code}`,
        method: 'PUT',
        data: { email, senha },
        enviarTokenIntegracao: true
      });

      let retError = {};
      //ERRO NO TOKEN
      if (ret.statusCode === 401) {
        retError = await invocarApi({
          url: `recuperarsenha/${code}`,
          method: 'PUT',
          data: { email, senha },
          enviarTokenIntegracao: true
        });
      }
      return { ...ret, ...retError };
    }
    ,
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    atualizarSenha,
  };
}
