export class Empresa {
    constructor(
       public Id?:  string,
       public Doc?: string,
       public Ie?: string,
       public Nome?:string,
       public Fant?: string,
       public Contato?: string,
       public Cep?: string,
       public Lgr?: string,
       public Nro?: string,
       public Cpl?: string,
       public Bairro?: string,
       public Municipio?: string,
       public UF?: string,
       public Pais?: string,
       public CodMun?: number,
       public CodPais?: number,
       public Telefone?: string,
       public Email?: any,
       public Site?: string,
       public Crt?: number,
       public Gmt?: number,
       public Cnae?: number,
       public IndStatus?: number,
       public InfAdic?: string,
       public tabelaPrecoId?: string,
       public contratoId?: string,

    ) { 
    }
}