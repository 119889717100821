import { useCallback } from 'react';
import { useApiGestao } from 'servicos/api-gestao/base';

export function useGerarPreContrato() {
    const { invocarApi, ...outrasPropriedades } = useApiGestao();

    const gerarPreContrato = useCallback(
        async (dominio: any) =>{
            const ret = await invocarApi({
              url: `contrato/precadastro/${dominio}`,
              enviarTokenIntegracao: true
            });
      
            let retError = {};
            //ERRO NO TOKEN
            if (ret.statusCode === 401) {
              retError = await invocarApi({
                url: `contrato/precadastro/${dominio}`,
                enviarTokenIntegracao: true,
                forcarTokenIntegracao: true
              });
            }
            return { ...ret, ...retError };
          }
          ,
        [invocarApi],
    );

    return {
        ...outrasPropriedades,
        gerarPreContrato,
    };
}
