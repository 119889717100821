import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      maxWidth: null,
    },
  },
  form: {
    width: '100%',
  },
  submit: {
    margin: '20px 0px',
  },
  card: {
    marginTop: '1rem',
    position: 'relative',
    width: '100%',
  },
  tituloPagina:{
    
  },
  pVoltar: {
    marginRight:'auto',
    paddingLeft:'7px'
  },
  iconBtn: { cursor: 'pointer', color: 'white' },

  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  accordion: {
    marginBottom: 10,
  },
  accordionDetails: {
    flexDirection: 'column',
  },
  accordionSumary: {
    transition: 'all 0.5s ease-in-out',

    '&:hover': {
      backgroundColor: '#0E72AC',
      color: 'white',
    },
    alignItems: 'center',
    display: 'flex',
  },
  active: {
    backgroundColor: '#0E72AC',
    color: 'white',
  },
  empresaNome: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(16),
  },

  cardModulo: {
    minHeight: '200px',
    minWidth: '200px',
    maxHeight: '200px',
    maxWidth: '200px',
  },
  cardItem: {
    flex: '0 0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  heading: {
    fontSize: '1.5rem',
    marginTop: 10,
    // fontWeight: 'bolder',
  },
  imgLogo: {
    width: '80px',
    height: '80px',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px',
  },
  //o carregando estava desalinhado no mobile
  carregando:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    textAlign:'center'
  },
}));
