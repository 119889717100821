import React from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { UFs } from 'dados';
import { UfSelectProps } from './uf-select-props';

export const UfSelect: React.FC<UfSelectProps> = ({ ufValue, onInputChange, ...outrasProps }) => {
  return (
    <Autocomplete
      options={UFs}
      getOptionLabel={(option) => option.sigla}
      fullWidth
      inputValue={ufValue}
      onInputChange={onInputChange}
      value={UFs.find(x => x.sigla === ufValue)}
      renderInput={(params) => (
        <TextField
          {...outrasProps}
          {...params}
        />
      )}
    />
  );
};
