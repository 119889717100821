import jwt_decode from 'jwt-decode';
import { isEmpty } from 'lodash';
import { TokenGestao, Modulo } from 'modelos';

export function converterTokenGestao(token: string): TokenGestao | null {
  try {
    if (isEmpty(token)) return null;

    let novoToken = jwt_decode(token) as TokenGestao;
    //aqui preparo os módulos de string para objeto e mando ele para a classe Modulo para ser tratado
    let mod = JSON.parse(novoToken.modulos);
    let arrayModulos = new Array<Modulo>();
    mod.forEach((item) => {
      arrayModulos.push(new Modulo(item.Modulo, item.Credenciais));
    });

    novoToken.modulosObj = arrayModulos;

    novoToken.token = token;

    return novoToken;
  } catch {
    return null;
  }
}
