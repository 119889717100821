import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: '25px',
    flexDirection: 'column',
  },
  icone: {
    margin: '20px 0px',
  },
}));
