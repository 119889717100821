import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    maxWidth: '850px',
  },
  step: {
    marginLeft: '10px',
  },
  progresso: {
    marginBottom: '60px',
    width: '100%',
  },
  submit: {
    margin: '20px 0px',
  },
  icone: {
    fontSize: '150px',
  },
}));
