import { useApiGestao } from 'servicos/api-gestao/base';
import { useCallback } from 'react';

export function useRefreshToken() {
  const { invocarApi, ...outrasPropriedades } = useApiGestao();

  const refreshToken = useCallback(
    async (token: string) => {
      const ret = await invocarApi({
        url: `token/integradores/refreshToken?token=${token}`,
        method: 'get',

        enviarTokenIntegracao: true
      });

      let retError = {};
      //ERRO NO TOKEN
      if (ret.statusCode === 401) {
        retError =await invocarApi({
            url: `token/integradores/refreshToken?token=${token}`,
            method: 'get',
    
            enviarTokenIntegracao: true
          });
      }
      return { ...ret, ...retError };
    }
    ,
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    refreshToken,
  };
}
