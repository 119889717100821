import { useApiGestao } from 'servicos/api-gestao/base';
import { useCallback } from 'react';
import { VariaveisAmbiente } from 'ambiente';

export function useRecuperarSenha() {
  const { invocarApi, ...outrasPropriedades } = useApiGestao();

  const getRecuperarSenha = useCallback(
    async (email: string) => {
      const frontUrl = `${VariaveisAmbiente.frontUrl}/login/atualizar-senha?token=@codRecuperacao&email=@email`;

      const ret = await invocarApi({
        url: `recuperarsenha`,
        method: 'POST',
        data: { email: btoa(email), urlBase: btoa(frontUrl) },
        enviarTokenIntegracao: true
      });

      let retError = {};
      //ERRO NO TOKEN
      if (ret.statusCode === 401) {
        retError = await invocarApi({
          url: `recuperarsenha`,
          method: 'POST',
          data: { email: btoa(email), urlBase: btoa(frontUrl) },
          enviarTokenIntegracao: true,
          forcarTokenIntegracao: true
        });
      }
      return { ...ret, ...retError };
    }
    ,
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getRecuperarSenha,
  };
}
