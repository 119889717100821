import React from 'react';
import { TextField, Button, Grid} from '@material-ui/core';
import { SenhaInput } from 'componentes';
import { useStyles } from './dados-usuario-form-styles';
import { DadosUsuarioFormProps } from './dados-usuario-form-props';
import { ValidationSchema } from './dados-usuario-form-validacao';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { DadosUsuarioSocial } from './dados-usuario-social'
export const DadosUsuariosForm = ({ dados, avancar, voltar, limparSocial }: DadosUsuarioFormProps) => {
  const classes = useStyles();

  const { values, handleSubmit, handleChange, handleBlur, errors, touched } = useFormik({
    initialValues: {
      ...dados.usuario,
      confirmacaoSenha: '',
    },
    validationSchema: ValidationSchema,
    onSubmit: (dadosSubmit) => avancar(dadosSubmit),
  });

  const nomeDisabled = !(isEmpty(values.facebookToken) && isEmpty(values.googleToken));
  const textoEmail = nomeDisabled ? "E-mail para recuperação de Senha" : "E-mail";

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Grid container spacing={0}>
        <DadosUsuarioSocial limparSocial={limparSocial} facebookToken={dados.usuario.facebookToken} googleToken={dados.usuario.googleToken} ></DadosUsuarioSocial>
        <Grid xs={12}>
          <TextField
            inputProps={{
              readOnly: nomeDisabled,
            }}
            label="Nome"
            name="nome"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.nome}
            error={Boolean(touched.nome && errors.nome)}
            helperText={touched.nome && errors.nome ? errors.nome : undefined}
            placeholder="Seu nome"
            autoFocus
          />
        </Grid>
        <Grid xs={12}>

          <TextField
            label={textoEmail}
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email ? errors.email : undefined}
            placeholder="usuario@empresa.com.br"
          />
        </Grid>
        <Grid container spacing={0}>
          <Grid xs={12} md={6}>
            <SenhaInput
              label="Senha"
              name="senha"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.senha}
              className={classes.espacarDireitaInput}
              error={Boolean(touched.senha && errors.senha)}
              helperText={touched.senha && errors.senha ? errors.senha : undefined}
              placeholder="Senha"
            />
          </Grid>
          <Grid xs={12} md={6}>
            <SenhaInput
              label="Confirme a senha"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirmacaoSenha}
              error={Boolean(touched.confirmacaoSenha && errors.confirmacaoSenha)}
              helperText={
                touched.confirmacaoSenha && errors.confirmacaoSenha ? errors.confirmacaoSenha : undefined
              }
              name="confirmacaoSenha"
              placeholder="Confirme a senha"
            />
          </Grid>
        </Grid>
        <Grid container className={classes.acoesContainer} wrap="nowrap">
          <Button onClick={voltar} color="secondary" className={classes.marginDireita} startIcon={<ArrowBackIos />} type="button">
            Voltar
          </Button>
          <Button type="submit" startIcon={<ArrowForwardIos />}>Continuar</Button>
        </Grid>
      </Grid>
    </form>
  );
};
