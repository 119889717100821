import { useWebServiceSaurusUtils } from 'servicos/webservice-saurus-utils/base';
import { useCallback } from 'react';

export function useConsultaCnpj() {
  const { invocarApi, ...outros } = useWebServiceSaurusUtils({
    operacao: 'retConsultaDocumento',
  });

  const consultarCNPJ = useCallback(
    (cnpj: string, uf: string) => {
      const xml = (senha: string) => `
        <?xml version="1.0" encoding="utf-8"?>
        <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
          <soap12:Body>
            <retConsultaDocumento xmlns="http://saurus.net.br/">
              <xXMLParametros><![CDATA[<xmlIntegracao><Documento>${cnpj.replace(/\D+/g, '')}</Documento><UF>${uf}</UF></xmlIntegracao>]]></xXMLParametros>
              <xSenha>${senha}</xSenha>
            </retConsultaDocumento>
          </soap12:Body>
        </soap12:Envelope>
    `;

      return invocarApi({
        xml,
      });
    },
    [invocarApi],
  );

  return {
    ...outros,
    consultarCNPJ,
  };
}
