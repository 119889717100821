import { merge } from 'lodash';
import { IVariaveisAmbiente, IAmbienteUnico } from './types';

const AmbienteEnviado = process.env.REACT_APP_ENV as keyof IVariaveisAmbiente;
export const AmbientePadrao: keyof IVariaveisAmbiente = AmbienteEnviado || '#{Ambiente}#';

const apiUrl = 'https://apisaurusgestao-master.azurewebsites.net';
const apiUrlLocal = 'https://localhost:44305';

const isDev = false; //mudar para alternar entre prod e dev

const _variaveisAmbiente: IVariaveisAmbiente = {
  all: {
    isDev: isDev,
    googleAppId: '27279191635-5kdincvmbm92lihatr0k4tm1o4kon8ue.apps.googleusercontent.com',
    saurusUtilsWebService: 'https://wsmaster.saurus.net.br/v003/servicemaster.asmx',
    apiUrl: isDev? apiUrlLocal : apiUrl
  },
  // Backup das informações do ambiente local; -- DEPRECATED --
  // local: {
  //   apiUrl: apiUrlLocal,
  //   facebookAppId: '661218711269424',
  //   frontUrl: 'http://localhost:3000',
  // },
  menuVip: {
    facebookAppId: '1880946222051906',
    frontUrl: 'https://auth.saurus.net.br',
    appName: 'menuVip'
  },
  saurusGestao: {
    facebookAppId: '1880946222051906',
    frontUrl: 'https://auth.saurus.net.br',
    appName: 'saurusGestao'
  },
  s2Pay: {
    facebookAppId: '1880946222051906',
    frontUrl: 'https://auth.saurus.net.br',
    appName: 's2Pay',
  }
};

const _ambienteUnico =
  (_variaveisAmbiente[AmbientePadrao] as IAmbienteUnico) || _variaveisAmbiente.saurusGestao;
export const VariaveisAmbiente = merge(_variaveisAmbiente.all, _ambienteUnico);
