import React from 'react';
import { AutenticacaoLayout, SistemaLayout } from 'layouts';
import {
  LoginPagina,
  EsqueciASenhaPagina,
  AtualizarSenha,
  NovoUsuarioPagina,
  SistemaPagina,
  AuthIntegradorPagina,
  BemVindoPagina,
  ConfiguracoesPagina,
  EmpresaCadastroPagina,

} from 'paginas';

import { PrivateRoute } from 'componentes';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { EmpresaEditPagina } from 'paginas/privado/empresa-edit';

export function Rotas() {
  return (
    <Router>
      <Switch>
        <PrivateRoute path="/teste" tokenAtivo={false}>
          <AutenticacaoLayout>
            <Switch>
              <Route exact path="/teste" component={() => <h1>teste2</h1>}></Route>
            </Switch>
          </AutenticacaoLayout>
        </PrivateRoute>
        <PrivateRoute path="/login" tokenAtivo={false}>
          <AutenticacaoLayout>
            <Switch>
              <Route exact path="/login/auth" component={AuthIntegradorPagina} />
              <Route exact path="/login/novo-usuario" component={NovoUsuarioPagina} />
              <Route exact path="/login/atualizar-senha" component={AtualizarSenha} />
              <Route exact path="/login/esqueci-a-senha" component={EsqueciASenhaPagina} />
              <Route exact path="/login/:parametro?" component={LoginPagina} />
            </Switch>
          </AutenticacaoLayout>
        </PrivateRoute>
        <PrivateRoute path="/bem-vindo" tokenAtivo={true}>
          <AutenticacaoLayout>
            <Switch>
              <Route exact path="/bem-vindo" component={BemVindoPagina} />
            </Switch>
          </AutenticacaoLayout>
        </PrivateRoute>

        <PrivateRoute path="/configuracoes" tokenAtivo={true}>
          <SistemaLayout>
            <Switch>
              <Route exact path="/configuracoes" component={ConfiguracoesPagina} />
            </Switch>
          </SistemaLayout>
        </PrivateRoute>

        <PrivateRoute path="/empresas" tokenAtivo={true}>
          <SistemaLayout>
            <Switch>
              <Route exact path="/empresas/nova-empresa" component={EmpresaCadastroPagina} />
              <Route exact path="/empresas/:id" component={EmpresaEditPagina} />
              <Route exact path="/empresas/:id/teste" component={ConfiguracoesPagina} />
            </Switch>
          </SistemaLayout>
        </PrivateRoute>
        <PrivateRoute path="/" tokenAtivo={true}>
          <SistemaLayout>
            <Switch>
              <Route exact path="/" component={SistemaPagina}></Route>
            </Switch>
          </SistemaLayout>
        </PrivateRoute>
      </Switch>
    </Router>
  );
}
