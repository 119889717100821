import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import {
  Grid,
  Button,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  Fab,
} from '@material-ui/core';
import { TituloComBorda, useNotificacoes, Carregando, Modal, GridModulo } from 'componentes';
import { useStyles } from './sistema-styles';
import { useFormik } from 'formik';
import { useLoginService } from 'servicos';
import { useHistory } from 'react-router-dom';
import { GestaoStorageKeys, useGestaoStorage, converterTokenGestao } from 'utils';
import { isEmpty, toLength } from 'lodash';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Acesso, TiposModulo, TokenGestao, Modulo } from 'modelos';


export function SistemaPagina() {
  const classes = useStyles();
  const { realizarLogout, carregando, setCarregandoTela } = useLoginService();
  const { showErrorMessage } = useNotificacoes();
  const history = useHistory();
  const { getRegistro, delRegistro } = useGestaoStorage();
  const { handleSubmit } = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      const { message, sucesso } = await realizarLogout();
      if (!sucesso) {
        showErrorMessage('Erro ao realizar o Logout. Detalhe: ' + message);
      } else {
        history.replace('/login');
      }
    },
  });

  //empresas
  const [acesso, setAcesso] = useState(new Acesso('', '', []));
  //token Deserializado
  const [token, setToken] = useState(new TokenGestao('', '', '', '', '', '', [], 0));

  const [open, setOpen] = React.useState(false);
  const [modulo, setModulo] = React.useState(new Modulo(0, ''));

  const handleOpen = (modulo) => {
    setModulo(modulo);
    setOpen(true);
  };


  //coloquei o useEffect para corrigir o fluxo
  useEffect(() => {
    setAcesso(getRegistro(GestaoStorageKeys.AcessoUsuario));
    setToken(
      converterTokenGestao(getRegistro(GestaoStorageKeys.TokenGestao)) ||
        new TokenGestao('', '', '', '', '', '', [], 0),
    );
  }, [getRegistro]);

  //limpa do storage, mesmo estando em sessão é bom limpar
  const limparStorage = () => {
    delRegistro(GestaoStorageKeys.CallbackAcao);
    delRegistro(GestaoStorageKeys.ValidarDominio);
    delRegistro(GestaoStorageKeys.TokenGestao);
    delRegistro(GestaoStorageKeys.TokenSocialFacebook);
    delRegistro(GestaoStorageKeys.TokenSocialGoogle);
    delRegistro(GestaoStorageKeys.AcessoUsuario);
  };
  //quando a empresa é selecionada
  const onEmpresaSelecionada = (acesso, modulo) => {
    switch (modulo.Modulo) {
      case TiposModulo.ConfigurarContrato:
        return onConfigurarContrato(acesso, modulo);
      case TiposModulo.MenuVip:
        return onCardapioSelecionado(acesso, modulo);
      case TiposModulo.PortalS2:
        return onPortalS2Selecionado(acesso, modulo);
    }
  };
  //faz o redirect para o cardápio
  const onCardapioSelecionado = (acesso, modulo) => {
    setCarregandoTela({
      carregando: true,
      titulo: 'Bem Vindo!',
      mensagem: `Aguarde mais um instante que estamos redirecionando você para o ${modulo.Nome}!`,
    });

    limparStorage();
    window.location.href = `https://cardapio.saurus.net.br/login?token=${token?.token}&empresaId=${acesso.empresaId}`;
  };

  //faz o redirect para o portals2
  const onPortalS2Selecionado = (acesso, modulo) => {
    setCarregandoTela({
      carregando: true,
      titulo: 'Bem Vindo!',
      mensagem: `Aguarde mais um instante que estamos redirecionando você para o ${modulo.Nome}!`,
    });
    limparStorage();
    window.location.href = `https://portal.s2pay.com.br/login?userToken=${token?.token}`;
  };

  //implementar ainda, pois não temos a tela
  const onConfigurarContrato = (acesso, modulo) => {
    history.push('/configuracoes');
  };

  const handleClose = () => {
    setOpen(false);
  };

  //volta para o login
  const voltar = () => {
    limparStorage();
    history.replace('/login');
  };

  //quando clica no accordion
  const onCardSelected = (event, moduloIndex) => {
    //paro a propagação do evento para previnir dar trigger em click onde não deve
    event.stopPropagation();
    //se só tiver uma empresa ou não for multiEmpresa já envia para os métodos de redirect
    if (acesso?.perfis.length === 1 || !token?.modulosObj[moduloIndex].IsMultiEmpresa) {
      onEmpresaSelecionada(acesso?.perfis[0], token?.modulosObj[moduloIndex]);
    } else {
      handleOpen(modulo);
    }
  };

  const handleClickGridModulo = (event, modulo) => {
    onCardSelected(event, modulo);
  }


  if (carregando.carregando) {
    return (
      <div className={classes.carregando}>
        <Carregando carregando={true} titulo={carregando.titulo} mensagem={carregando.mensagem} />
      </div>
    );
  }

  //tinha vez q dava exception aqui só para previnir
  if (acesso !== null && acesso !== undefined && Object.entries(acesso).length !== 0) {
    if (isEmpty(acesso?.perfis[0]?.empresaId)) {
      return (
        <Grid item container xs={10} md={12} className={classes.container} justify="center">
          <TituloComBorda titulo="Bem Vindo!" />
          <form onSubmit={handleSubmit} className={classes.form}>
            <Button type="submit" className={classes.submit}>
              Deslogar
            </Button>
          </form>
        </Grid>
      );
    }
  }

  //se não houver módulo ativo
  if (token?.modulosObj?.filter((x) => x.IsAtivo).length === 0) {
    showErrorMessage('Erro ao selecionar sistema, motivo: a conta não possui nenhum módulo ativo');
    limparStorage();
    history.replace('/login');
  }
  //se só tiver 1 módulo ativo e só 1 perfil vai direto
  if (token?.modulosObj?.filter((x) => x.IsAtivo).length === 1) {
    let moduloUnico = token?.modulosObj?.filter((x) => x.IsAtivo)[0];
    if (!moduloUnico?.IsMultiEmpresa || acesso?.perfis.length === 1) {
      onEmpresaSelecionada(acesso?.perfis[0], moduloUnico);
    }
  }

  return (
    <>
      <Grid
        xs={10}
        spacing={3}
        container
        direction="column"
        justify="center"
        className={classes.container}
        item
      >
        <TituloComBorda titulo="Selecione um Módulo">
          <Fab color="secondary" aria-label="voltar" size="small" className={classes.pVoltar}>
            <ArrowBackIosIcon className={classes.iconBtn} onClick={() => voltar()} />
          </Fab>
        </TituloComBorda>
        <GridModulo clickFunc={onCardSelected.bind(this)} moduloList={token?.modulosObj} ></GridModulo>
        <Modal
          tamanho="md"
          aberto={open}
          quandoForFechado={handleClose}
          carregando={
            <Carregando
              carregando={carregando.carregando}
              titulo={carregando.titulo}
              mensagem={carregando.mensagem}
            />
          }
          titulo="Selecione a Empresa"
        >
          {acesso.perfis?.map((acesso: any) => (
            <Card key={acesso.empresaId} className={classes.card}>
              <CardActionArea>
                <CardContent onClick={() => onEmpresaSelecionada(acesso, modulo)}>
                  <Typography color="textSecondary">
                    {acesso?.empresaDoc.length <= 11
                      ? acesso?.empresaDoc.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
                      : acesso?.empresaDoc.replace(
                          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                          '$1.$2.$3/$4-$5',
                        )}
                  </Typography>
                  <Typography variant="subtitle1" component="h2" className={classes.empresaNome}>
                    {acesso?.empresaFant}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Modal>
     
      </Grid>
    </>
  );
}
