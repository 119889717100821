import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  titulo: {
    marginBottom: '10px',
    display: 'inline-block',
    flex: '1 1 100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  border: {
    height: '3px',
    width: '100%',
    marginBottom: '10px',
    background: theme.palette.primary.main,
  },
}));
