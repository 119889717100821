import { useCallback } from 'react';
import { useApiGestao } from 'servicos/api-gestao/base';

export function useGetTokenGoogle() {
  const { invocarApi, ...outrasPropriedades } = useApiGestao();

  const getTokenGoogle = useCallback(
    async (accessToken: string) =>{
      const ret = await invocarApi({
        url: `token/google/${accessToken}`,
        enviarTokenIntegracao: true
      });

      let retError = {};
      //ERRO NO TOKEN
      if (ret.statusCode === 401) {
        retError = await invocarApi({
          url: `token/google/${accessToken}`,
          enviarTokenIntegracao: true,
          forcarTokenIntegracao: true
        });
      }
      return { ...ret, ...retError };
    }
    ,
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getTokenGoogle,
  };
}
