import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  stepper: {
    background: 'transparent',
    width: '100%',
    paddingLeft: '0',
    paddingRight: '0',
  },
}));
