import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  cardModulo: {
    minHeight: '200px',
    minWidth: '200px',
    maxHeight: '200px',
    maxWidth: '200px',
  },
  
  cardItem: {
    flex: '0 0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px',
  },

  heading: {
    fontSize: '1.5rem',
    marginTop: 10,
    // fontWeight: 'bolder',
  },
  imgLogo: {
    width: '80px',
    height: '80px',
  },
  textContainer: {
    width: '99%',
    textAlign: 'center',
  },

  
}));
