import { AxiosError, AxiosResponse } from 'axios';


export enum TipoRetornoEnum {
  Sucesso,
  Local,
  Api,
  Servidor
}

export function FormatarResposta(error?: AxiosError<any>, resposta?: AxiosResponse<any>) {
  
  
  const statusCode = resposta?.status || 0;
  const sucesso = Boolean(resposta!?.status >= 200 && resposta!?.status <= 299);
  const houveErro = Boolean(error) || !Boolean(resposta);
  const erroDeInternet = houveErro && Boolean(!error?.response) === true;
  const erroDeUsuario = Boolean(
    error?.response && error.response.status >= 400 && error.response.status <= 499,
  );
  // const erroDeServidor =
  //   error?.response && error.response.status >= 500 && error.response.status <= 599;

  return {
    tipoRetorno: 
      sucesso? TipoRetornoEnum.Sucesso : 
      (erroDeUsuario ? TipoRetornoEnum.Api : 
        erroDeInternet ? TipoRetornoEnum.Local : 
        TipoRetornoEnum.Servidor
        ),
    statusCode 
  };
}
