import React from 'react';
import { InputComMascara } from 'componentes/inputs/input-com-mascara';
import { TextFieldProps } from '@material-ui/core';

const telefone = '( 99 ) 9999-99999';

export function TelefoneInput(props: TextFieldProps) {
  return (
    <InputComMascara
      {...props}
      guiarUsuario={false}
      naoMostrarMascaraComoCaracter={false}
      mascara={telefone}
    />
  );
}
