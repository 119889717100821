import React from 'react';
import { TituloComBorda, Carregando, Stepper } from 'componentes';
import { Grid } from '@material-ui/core';
import { useStyles } from './novo-usuario-styles';
import {
  DadosUsuariosForm,
  PesquisarEmpresaForm,
  DadosEmpresaForm,
  NotificacaoEstatica,
} from './componentes';
import { useNovoUsuarioLogica } from './novo-usuario-logica';
import { StepsEnum, StepsVisiveis } from './novo-usuario-dados';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { isEmpty } from 'lodash';
export const NovoUsuarioPagina: React.FC = () => {
  const {
    stepAtual,
    dados,
    limparSocialUsuario,
    avancarStepUsuario,
    avancarStepPesquisarEmpresa,
    avancarStepCadastrarEmpresa,
    voltarStep,
    acessarEmpresa,
    voltarLogin,
    carregando,
  } = useNovoUsuarioLogica();
  const classes = useStyles({ cadastroDadosEmpresa: stepAtual.id === StepsEnum.CadastrarEmpresa });

  if (carregando.carregando) {
    return <Carregando carregando={carregando.carregando} mensagem={carregando.mensagem} titulo={carregando.titulo} />;
  }

  return (
    <Grid xs={10} container className={classes.container} justify="center">
      <Grid item xs={12} container justify={'center'}>
        {stepAtual.id !== StepsEnum.Sucesso
        && stepAtual.id !== StepsEnum.PedirConvite && (
          <Stepper steps={StepsVisiveis} stepAtual={stepAtual.index} />
        )}
      </Grid>
      <Grid item xs={12} container justify="center">
        <TituloComBorda titulo={stepAtual.titulo} />
        <Grid item xs={12}>
          {stepAtual.ComponentHeader && stepAtual.ComponentHeader(dados)}
        </Grid>
        {stepAtual.id === StepsEnum.CadastrarUsuario && (
          <DadosUsuariosForm dados={dados} avancar={avancarStepUsuario} voltar={voltarStep(StepsEnum.Inicial)} limparSocial={limparSocialUsuario} />
        )}
        {stepAtual.id === StepsEnum.ConsultarEmpresa && (
          <PesquisarEmpresaForm
            dominio={dados.dominio}
            avancar={avancarStepPesquisarEmpresa}
            voltar={voltarStep(StepsEnum.CadastrarUsuario)}
          />
        )}
        {stepAtual.id === StepsEnum.CadastrarEmpresa && (
          <DadosEmpresaForm
            textoBotao="Concluir Cadastro"
            empresa={dados?.empresa}
            voltar={voltarStep(StepsEnum.ConsultarEmpresa)}
            avancar={avancarStepCadastrarEmpresa}
          />
        )}
        {stepAtual.id === StepsEnum.Sucesso && (
          <NotificacaoEstatica
            mensagem={`Seja bem vindo ${dados.usuario.nome}! Seu cadastro foi realizado com sucesso e você poderá realizar o login através do email ${dados.usuario.email + (!isEmpty(dados.usuario.facebookToken) || !isEmpty(dados.usuario.googleToken) ? " ou através do acesso das redes sociais" : "")}.`}
            acaoTexto="Realizar Login"
            acao={acessarEmpresa}
            acaoIcone={<AccountCircleIcon />}
          >
            <CheckCircleIcon color="primary" className={classes.icone} />
          </NotificacaoEstatica>
        )}
        {stepAtual.id === StepsEnum.PedirConvite && (
          <NotificacaoEstatica
            mensagem={`O CNPJ ${dados.empresa.Doc} já possui um cadastro ativo em nossa base de dados. Solicite ao adiministrador que envie um convite ou realize o login para obter acesso à empresa.`}
            acaoTexto="Voltar ao Login"
            acaoIcone={<AccountCircleIcon />}
            acao={voltarLogin}
          >
            <WarningIcon color="error" className={classes.icone} />
          </NotificacaoEstatica>
        )}
      </Grid>
    </Grid>
  );
};
