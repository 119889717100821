import { useCallback, useState } from 'react';
import { Acesso } from 'modelos';
import {
  useAtualizarSenha,
  useGetAcesso,
  useGetTokenFacebook,
  useGetTokenGoogle,
  useGetTokenUsuario,
  useRecuperarSenha,
  useRefreshToken,
} from 'servicos/api-gestao';
import { GestaoStorageKeys, useGestaoStorage, converterTokenGestao } from 'utils';
import { useConfirm } from 'material-ui-confirm';
import { UserAccounts, TiposLogin } from 'utils/users-accounts';

export function useLoginService() {
  const confirm = useConfirm();

  const { getAcesso } = useGetAcesso();
  const { getTokenFacebook } = useGetTokenFacebook();
  const { getTokenGoogle } = useGetTokenGoogle();
  const { getToken } = useGetTokenUsuario();
  const { getRecuperarSenha } = useRecuperarSenha();
  const { atualizarSenha } = useAtualizarSenha();
  const { refreshToken } = useRefreshToken();
  const { delRegistro, exists, getRegistro, setRegistro } = useGestaoStorage();
  const [carregandoTela, setCarregandoTela] = useState({
    carregando: false,
    mensagem: '',
    titulo: '',
  });

  const { setUser } = UserAccounts();

  const [continuarCadastroSocial, setContinuarCadastroSocial] = useState(false);

  const tratarRetornoLogin = useCallback(
    async (
      response: any,
      tpLogin,
      manterConectado: boolean
    ) => {
      setCarregandoTela((previa) => ({ ...previa, titulo: 'Validando Retorno' }));
      if (!response?.data?.token) throw new Error('Token de acesso não foi identificado.');
      setCarregandoTela((previa) => ({ ...previa, titulo: 'Solicitando Permissões' }));

      let decodedToken = converterTokenGestao(response?.data?.token);

      setRegistro(GestaoStorageKeys.TokenGestao, response?.data?.token);
      const { erro, resultado } = await getAcesso();
      if (erro) {
        delRegistro(GestaoStorageKeys.TokenGestao);
        throw new Error(
          `Não foi possível identificar o perfil do seu usuário. Detalhe: ${erro?.message}`,
        );
      }

      setRegistro(GestaoStorageKeys.AcessoUsuario, resultado?.data);
      if (manterConectado) {
        setUser(
          decodedToken?.usuarioEmail,
          response?.data?.token,
          decodedToken?.usuarioNome,
          tpLogin,
        );
      }
      setCarregandoTela({ carregando: false, titulo: '', mensagem: '' });
      return { message: 'Login realizado com Sucesso!', sucesso: true };
    },
    [delRegistro, getAcesso, setRegistro, setUser],
  );

  const logarPorContaLogada = useCallback(
    async (token, tpLogin, manterConectado: boolean) => {
      setCarregandoTela((previa) => ({
        ...previa,
        carregando: true,
        mensagem: 'Aguarde enquanto validamos suas informações',
        titulo: 'Realizando Login',
      }));
      const { erro, resultado } = await refreshToken(token);

      if (erro) {
        setCarregandoTela({ carregando: false, titulo: '', mensagem: '' });
        return { message: 'ocorreu um error ao logar com conta selecionada, favor informe novamente as credenciais ou outra conta', sucesso: false };
      }
      return tratarRetornoLogin(resultado, tpLogin, manterConectado);

    },
    [refreshToken, tratarRetornoLogin],
  );

  const realizarLogin = useCallback(
    async (
      usuario: string,
      senha: string,
      googleToken: any,
      facebookToken: any,
      manterConectado: boolean,
    ) => {
      try {
        setCarregandoTela((previa) => ({
          ...previa,
          carregando: true,
          mensagem: 'Aguarde enquanto validamos suas informações',
          titulo: 'Realizando Login',
        }));

        delRegistro(GestaoStorageKeys.TokenSocialFacebook);
        delRegistro(GestaoStorageKeys.TokenSocialGoogle);
        delRegistro(GestaoStorageKeys.TokenGestao);
        delRegistro(GestaoStorageKeys.AcessoUsuario);

        let res: any;
        let tpLogin = 0;

        if (facebookToken) {
          setCarregandoTela((previa) => ({ ...previa, titulo: 'Verificando Facebook' }));
          const { erro, resultado, statusCode } = await getTokenFacebook(facebookToken.accessToken);
          if (erro) {
            //MANDAR CADASTRAR
            if (statusCode === 410) {
              setCarregandoTela({ carregando: false, titulo: '', mensagem: '' });

              confirm({
                title: 'Novo por aqui?',
                description: `A conta do Facebook (${facebookToken.id}) informada não possui cadastro em nossa base de dados. Deseja iniciar seu cadastro agora?`,
                cancellationText: 'Não',
                confirmationText: 'Sim',
              }).then(() => {
                setRegistro(GestaoStorageKeys.TokenSocialFacebook, facebookToken);
                setContinuarCadastroSocial(true);
              });

              return { message: '', sucesso: null };
            }
            throw new Error(`${erro.message}`);
          }
          tpLogin = TiposLogin.Facebook;
          res = resultado;
        } else if (googleToken) {
          setCarregandoTela((previa) => ({ ...previa, titulo: 'Verificando Google' }));
          const { erro, resultado, statusCode } = await getTokenGoogle(googleToken.accessToken);
          if (erro) {
            //MANDAR CADASTRAR
            if (statusCode === 410) {
              setCarregandoTela({ carregando: false, titulo: '', mensagem: '' });

              confirm({
                title: 'Novo por aqui?',
                description: `A conta do Google (${googleToken.profileObj.email}) informada não possui cadastro em nossa base de dados. Deseja iniciar seu cadastro agora?`,
                cancellationText: 'Não',
                confirmationText: 'Sim',
              }).then(() => {
                setRegistro(GestaoStorageKeys.TokenSocialGoogle, googleToken);
                setContinuarCadastroSocial(true);
              });

              return { message: '', sucesso: null };
            }
            throw new Error(`${erro.message}`);
          }
          tpLogin = TiposLogin.Google;
          res = resultado;
        } else {
          setCarregandoTela((previa) => ({ ...previa, titulo: 'Verificando Login' }));
          const { erro, resultado } = await getToken(usuario, senha);
          if (erro) {
            throw erro;
          }
          tpLogin = TiposLogin.Manual;
          res = resultado;
        }

        return await tratarRetornoLogin(res, tpLogin, manterConectado);
      } catch (e) {
        setCarregandoTela({ carregando: false, titulo: '', mensagem: '' });
        return { message: e.message, sucesso: false };
      }
    },
    [
      delRegistro,
      getToken,
      getTokenFacebook,
      getTokenGoogle,
      tratarRetornoLogin,
      confirm,
      setRegistro,
    ],
  );

  const retornaTokenAtual = useCallback(() => {
    if (exists(GestaoStorageKeys.TokenGestao)) {
      const tok = getRegistro(GestaoStorageKeys.TokenGestao);
      let jwt = converterTokenGestao(tok);

      if (!jwt || jwt.exp * 1000 < new Date().getTime()) {
        delRegistro(GestaoStorageKeys.TokenGestao);
        return null;
      }
      return jwt;
    }
    return null;
  }, [delRegistro, exists, getRegistro]);

  const retornaDadosUsuario = useCallback(() => {
    if (exists(GestaoStorageKeys.AcessoUsuario)) {
      return getRegistro(GestaoStorageKeys.AcessoUsuario) as Acesso;
    }
    return null;
  }, [exists, getRegistro]);

  const recuperarSenha = useCallback(
    async (email: string) => {
      try {
        setCarregandoTela({
          carregando: true,
          titulo: 'Enviando Solicitação',
          mensagem: 'Aguarde enquanto solicitamos a redefinição de sua senha...',
        });
        const { erro, resultado } = await getRecuperarSenha(email);
        if (erro) {
          throw erro;
        }
        return { message: resultado, sucesso: true };
      } catch (e) {
        setCarregandoTela({ carregando: false, titulo: '', mensagem: '' });
        return { message: e.message, sucesso: false };
      }
    },
    [getRecuperarSenha],
  );

  const alterarSenha = useCallback(
    async (code: string, email: string, senha: string) => {
      try {
        setCarregandoTela({
          carregando: true,
          titulo: 'Redefinindo Senha',
          mensagem: 'Aguarde enquanto solicitamos a redefinição de sua senha...',
        });
        const { erro, resultado } = await atualizarSenha(code, email, senha);
        if (erro) {
          throw erro;
        }
        return { message: resultado, sucesso: true };
      } catch (e) {
        setCarregandoTela({ carregando: false, titulo: '', mensagem: '' });
        return { message: e.message, sucesso: false };
      }
    },
    [atualizarSenha],
  );

  const realizarLogout = useCallback(async () => {
    try {
      setCarregandoTela({
        carregando: true,
        titulo: 'Realizando Logout',
        mensagem: 'Aguarde enquanto realizamos logout da sua conta.',
      });

      delRegistro(GestaoStorageKeys.TokenGestao);
      delRegistro(GestaoStorageKeys.AcessoUsuario);

      return { message: 'Logout realizado com Sucesso!', sucesso: true };
    } catch (e) {
      setCarregandoTela({ carregando: false, titulo: '', mensagem: '' });
      return { message: e.message, sucesso: false };
    }
  }, [delRegistro]);

  return {
    realizarLogin,
    retornaTokenAtual,
    retornaDadosUsuario,
    recuperarSenha,
    alterarSenha,
    realizarLogout,
    setCarregandoTela,
    logarPorContaLogada,
    continuarCadastroSocial,
    carregando: carregandoTela,
  };
}
