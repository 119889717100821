import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { usePrivateRoute } from './use-private-route'
type PrivateRouteProps = RouteProps & {
    tokenAtivo: boolean;
};
export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, ...otherProps }) => {
    const { tokenValido } = usePrivateRoute();
    return (
        otherProps.tokenAtivo ?
            <Route {...otherProps}>{tokenValido ?
                children :
                <Redirect to="./login/expirou" />}
            </Route>
            :
            <Route {...otherProps}>{!tokenValido ?
                children :
                <Redirect to="./" />}
            </Route>);
}