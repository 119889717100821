import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    background: 'transparent',
    maxWidth: '500px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: null,
    },
  },
  form: {
    width: '100%',
    marginTop: 20,
  },
  pVoltar: {
    marginRight: 'auto',
  },
  //pq quando publicou não tava pegando o style certo
  btnTooltip: {
    paddingLeft:'7px',
    display:'flex'
  },
  iconBtn: { cursor: 'pointer', color: 'white' },
  imgButton: {
    width: '35px',
    height: '35px',
    objectFit: 'contain',
  },
  iconButton: {
    margin: '0px 5px',
    cursor: 'pointer',
  },
  card: {
    marginTop: '1rem',
    position: 'relative',
  },

  buttonClose: {
    marginLeft: 'auto',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
  },
  dadosUsuario: {
    flexDirection: 'column',
    marginLeft: '1rem',
  },
  outraConta: {
    display: 'flex',
    alignItems: 'center',
    '& h2': {
      marginLeft: '1rem',
    },
  },
}));
