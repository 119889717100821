import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import {
  Grid,
  Button,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  Fab,
} from '@material-ui/core';
import { TituloComBorda, useNotificacoes, Carregando, Modal, CardIconList, GridModulo, CardIcon } from 'componentes';
import { useStyles } from './configuracoes-styles';
import { useFormik } from 'formik';
import { useGetEmpresa, useLoginService } from 'servicos';
import { useHistory } from 'react-router-dom';
import { GestaoStorageKeys, useGestaoStorage, converterTokenGestao } from 'utils';
import { isEmpty, toLength } from 'lodash';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Acesso, TiposModulo, TokenGestao, Modulo } from 'modelos';
import { Person, Store } from '@material-ui/icons';


export function ConfiguracoesPagina() {
  const classes = useStyles();
  const { realizarLogout, carregando, setCarregandoTela } = useLoginService();
  const { showErrorMessage } = useNotificacoes();
  const history = useHistory();
  const { getRegistro, delRegistro } = useGestaoStorage();
  const { getEmpresas, carregando: carregandoEmpresa, setCarregando: setCarregandoEmpresa } = useGetEmpresa();
  const [empresas, setEmpresa] = useState<any>(null);

  const { handleSubmit } = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      const { message, sucesso } = await realizarLogout();
      if (!sucesso) {
        showErrorMessage('Erro ao realizar o Logout. Detalhe: ' + message);
      } else {
        history.replace('/login');
      }
    },
  });

  //empresas
  const [acesso, setAcesso] = useState(new Acesso('', '', []));
  //token Deserializado
  const [token, setToken] = useState(new TokenGestao('', '', '', '', '', '', [], 0));

  const [open, setOpen] = React.useState(false);
  const [modulo, setModulo] = React.useState(new Modulo(0, ''));

  //coloquei o useEffect para corrigir o fluxo
  useEffect(() => {
    setAcesso(getRegistro(GestaoStorageKeys.AcessoUsuario));
    setToken(
      converterTokenGestao(getRegistro(GestaoStorageKeys.TokenGestao)) ||
      new TokenGestao('', '', '', '', '', '', [], 0),
    );
  }, [getRegistro]);


  const getEmpresaApi = async () => {
    const result = await getEmpresas();
    setEmpresa(result.resultado.data);
  }
  useEffect(() => {
    getEmpresaApi();
  }, []);


  //limpa do storage, mesmo estando em sessão é bom limpar
  const limparStorage = () => {
    delRegistro(GestaoStorageKeys.CallbackAcao);
    delRegistro(GestaoStorageKeys.ValidarDominio);
    delRegistro(GestaoStorageKeys.TokenGestao);
    delRegistro(GestaoStorageKeys.TokenSocialFacebook);
    delRegistro(GestaoStorageKeys.TokenSocialGoogle);
    delRegistro(GestaoStorageKeys.AcessoUsuario);
  };



  //volta para o login
  const voltar = () => {
    history.replace('/');
  };

  if (carregando.carregando) {
    return (
      <div className={classes.carregando}>
        <Carregando carregando={true} titulo={carregando.titulo} mensagem={carregando.mensagem} />
      </div>
    );
  }


  if (carregandoEmpresa) {
    return (
      <div className={classes.carregando}>
        <Carregando carregando={true} titulo={carregando.titulo} mensagem={carregando.mensagem} />
      </div>
    );
  }

  //tinha vez q dava exception aqui só para previnir
  if (acesso !== null && acesso !== undefined && Object.entries(acesso).length !== 0) {
    if (isEmpty(acesso?.perfis[0]?.empresaId)) {
      return (
        <Grid>
          <Grid item container xs={10} md={12} className={classes.container} justify="center">
            <TituloComBorda titulo="Bem Vindo!" />
            <form onSubmit={handleSubmit} className={classes.form}>
              <Button type="submit" className={classes.submit}>
                Deslogar
              </Button>
            </form>
          </Grid>
        </Grid>
      );
    }
  }

  //se não houver módulo ativo
  if (token?.modulosObj?.filter((x) => x.IsAtivo).length === 0) {
    showErrorMessage('Erro ao selecionar sistema, motivo: a conta não possui nenhum módulo ativo');
    limparStorage();
    history.replace('/login');
  }

  function montaListaDeEMpresas() {
    if (empresas != null) {
      return (
        empresas.map((empresa: any, index) => (
          <Card key={index} className={classes.card}>
            <CardActionArea>
              <CardContent onClick={() => history.push('/empresas/' + empresa.id)}>
                <Typography color="textSecondary">
                  {empresa?.doc.length <= 11
                    ? empresa?.doc.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
                    : empresa?.doc.replace(
                      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                      '$1.$2.$3/$4-$5',
                    )}
                </Typography>
                <Typography variant="subtitle1" component="h2" className={classes.empresaNome}>
                  {empresa?.fant}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))
      );
    } else {
      return null;
    }
  }

 

  return (
    <>
      <div className={classes.cardContainer}>
        <TituloComBorda titulo="Empresas do Contrato">
          <Fab color="secondary" aria-label="voltar" size="small" className={classes.pVoltar}>
            <ArrowBackIosIcon className={classes.iconBtn} onClick={() => voltar()} />
          </Fab>
        </TituloComBorda>
        {montaListaDeEMpresas()}
        <Card className={classes.card} onClick={() => history.push('/empresas/nova-empresa')}>
          <CardActionArea>
            <CardContent>
              <div className={classes.novaEmpresa}>
                <Store className={classes.imgButton} />
                <Typography variant="h5" component="h2">
                  Nova empresa
                </Typography>
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    </>
  );
}
