import React from 'react';
import { useResources } from 'resources';
interface DocumentTitleProps {

}

export const DocumentTitle = ({  }: DocumentTitleProps) => {
  const resources = useResources();
  React.useEffect(() => {
    try {
      let link;
      link = document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = resources.favIcon;

      let tituloPagina;
      tituloPagina = document.createElement('title');
      tituloPagina.innerText = resources.tituloPagina;
      document.getElementsByTagName('head')[0].appendChild(link);
      document.getElementsByTagName('head')[0].appendChild(tituloPagina);

    } catch {}
  }, [resources.favIcon, resources.tituloPagina]);

  return null;
};
