import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
  nome: Yup.string().required('Infome seu nome.'),
  email: Yup.string().email('O e-mail informado está inválido').required('O e-mail é obrigatório.'),
  senha: Yup.string().required('A senha é obrigatória.').min(4, 'Senha inválida. (4-32 caracteres)'),
  confirmacaoSenha: Yup.string()
    .required('A confirmação da senha é obrigatória.')
    .oneOf([Yup.ref('senha')], 'A confirmação diverge da senha informada.'),
});
