import { isEmpty } from 'lodash';

const ConstruirDigito = (cnpjArray: number[]) => {
  const total =
    [...cnpjArray]
      .reverse()
      .map((current, index) => {
        const fator = index + 2 > 9 ? index - 6 : index + 2;

        return current * fator;
      })
      .reduce((previous, current) => previous + current, 0) % 11;

  return total < 2 ? 0 : 11 - total;
};

export function CnpjValido(cnpj: string): boolean {
  const cnpjApenasComNumeros = (cnpj || '').replace(/\D+/g, '');

  if (cnpjApenasComNumeros.length !== 14) {
    return false;
  }

  const cnpjArray = cnpjApenasComNumeros.split('').map((numero) => Number.parseInt(numero, 10));
  const todosOsDigitosSaoIguais = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].find((atual) =>
    cnpjArray.every((digito) => digito === atual),
  );

  if (Boolean(todosOsDigitosSaoIguais) || todosOsDigitosSaoIguais === 0) {
    return false;
  }

  const cnpjSemDigito = cnpjArray.slice(0, cnpjArray.length - 2);
  const primeiroDigito = ConstruirDigito(cnpjSemDigito);
  const segundoDigito = ConstruirDigito([...cnpjSemDigito, primeiroDigito]);

  return `${cnpjSemDigito.join('')}${primeiroDigito}${segundoDigito}` === cnpjApenasComNumeros;
}

export function CnpjDominioValido(cnpj: string): boolean {
  if (isEmpty(cnpj))
    return false;

  if (cnpj.replace(/\D/g, '').length === cnpj.length) {
    return CnpjValido(cnpj);
  }

  return true;
}
