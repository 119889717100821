import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    background: 'transparent',
    maxWidth: '500px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: null
    },
  },
  form: {
    width: '100%',
  },
  submit: {
    margin: '20px 0px',
  },
}));
