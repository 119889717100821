import React from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from './card-icon-list-styles';

import { CardIconListProps } from './card-icon-list-props';

import { Modulo, TiposModulo } from 'modelos';
import { CardIcon } from 'componentes';



export const CardIconList = ({ cardList, clickFunc }: CardIconListProps) => {
  const classes = useStyles();
  if (cardList == null) return (<> </>);
  return (
    <>
      {cardList.map((card, index) =>
        <Grid item key={index}>
          <CardIcon texto={card.texto} clickFunc={(event) => {card.clickFunc(event, index)}} icon={card.icon}></CardIcon>
        </Grid>
      )}
    </>
  );
};
