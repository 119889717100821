import { useCallback } from 'react';
import { useApiGestao } from 'servicos/api-gestao/base';

export function useEmpresaExiste() {
  const { invocarApi, ...outrasPropriedades } = useApiGestao();

  const verificarSeEmpresaExiste = useCallback(
    async (cnpj: string) => {
      const ret = await invocarApi({
        url: `empresa/exists/${(cnpj || '').replace(/\D+/g, '')}`,
        enviarTokenIntegracao: true
      });

      let retError = {};
      //ERRO NO TOKEN
      if (ret.statusCode === 401) {
        retError = await invocarApi({
          url: `empresa/exists/${(cnpj || '').replace(/\D+/g, '')}`,
          enviarTokenIntegracao: true,
          forcarTokenIntegracao: true
        });
      }
      return { ...ret, ...retError };
    },

    [invocarApi],
  );

  return {
    ...outrasPropriedades,
    verificarSeEmpresaExiste,
  };
}
