import React, { useCallback, useEffect } from 'react';
import useState from 'react-usestateref';
import {
  Grid,
  Button,
  Typography,
} from '@material-ui/core';
import { TituloComBorda, useNotificacoes, Carregando, Modal, GridModulo } from 'componentes';
import { useStyles } from './empresa-cadastro-styles';
import { useFormik } from 'formik';
import { useGetTabelaPreco, useLoginService, useCadastrarEmpresa } from 'servicos';
import { useHistory } from 'react-router-dom';
import { GestaoStorageKeys, useGestaoStorage, converterTokenGestao } from 'utils';
import { isEmpty } from 'lodash';

import { Acesso, TokenGestao, Modulo, Empresa, TipoForm } from 'modelos';
import { DadosEmpresaForm } from 'paginas/publico/novo-usuario/componentes';


export function EmpresaCadastroPagina() {
  const classes = useStyles();
  const { realizarLogout, carregando, setCarregandoTela } = useLoginService();
  const { showErrorMessage, showSuccessMessage } = useNotificacoes();
  const history = useHistory();
  const { getRegistro, delRegistro } = useGestaoStorage();


  //empresas
  const [acesso, setAcesso] = useState(new Acesso('', '', []));
  //token Deserializado
  const [token, setToken] = useState(new TokenGestao('', '', '', '', '', '', [], 0));

  const [carregandoForm, setCarregandoForm] = useState(true);


  //coloquei o useEffect para corrigir o fluxo
  useEffect(() => {
    setAcesso(getRegistro(GestaoStorageKeys.AcessoUsuario));
    setToken(
      converterTokenGestao(getRegistro(GestaoStorageKeys.TokenGestao)) ||
      new TokenGestao('', '', '', '', '', '', [], 0),
    );
  }, [getRegistro]);


  const [empresa, setEmpresa] = useState<Empresa>(null);


  const getTabelasPrecoApi = async () => {
    const result = await getTabelasPreco();
    const empresaModel = new Empresa('', '', '', '', '', '', '', '', '', '', '', '', '', '', 0, 0, '', undefined, '', 0, 0, 0, 1, '', result.resultado.data[0].id, acesso.contratoId);
    setEmpresa(empresaModel);
  }
    useEffect(() => {
      getTabelasPrecoApi();
    }, []);
  
    useEffect(()=>{
      if(empresa !== null){
        setCarregandoForm(false)
      }
    }, [empresa])
  
  
  const { handleSubmit } = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      const { message, sucesso } = await realizarLogout();
      if (!sucesso) {
        showErrorMessage('Erro ao realizar o Logout. Detalhe: ' + message);
      } else {
        history.replace('/login');
      }
    },
  });

  const { getTabelasPreco } = useGetTabelaPreco();

  const { postEmpresa } = useCadastrarEmpresa()


  const postEmpresaApi = async (empresa: Empresa) => {
    if (empresa != null) {
      if (empresa.Cep != null) {
        empresa.Id = '00000000-0000-0000-0000-000000000000';
        empresa.Cep = empresa?.Cep?.replace(/\D/g, '');
        empresa.Doc =  empresa?.Doc?.replace(/\D/g, '');
        empresa.contratoId = acesso.contratoId;
        empresa.Email = null;
        empresa.Gmt = 3;
        empresa.Pais= 'Brasil';
        empresa.CodPais = 1058;
        empresa.IndStatus = 1;
      }
      const result = await postEmpresa(empresa, token.token);
      console.log(result)
      if (result != null) {
        if (result.statusCode == 200) {
          showSuccessMessage('Dados atualizados com sucesso');
          history.push('/configuracoes')
        } else {
          showErrorMessage('Algo deu errado');
        }
      }
    }
  }

  //limpa do storage, mesmo estando em sessão é bom limpar
  const limparStorage = () => {
    delRegistro(GestaoStorageKeys.CallbackAcao);
    delRegistro(GestaoStorageKeys.ValidarDominio);
    delRegistro(GestaoStorageKeys.TokenGestao);
    delRegistro(GestaoStorageKeys.TokenSocialFacebook);
    delRegistro(GestaoStorageKeys.TokenSocialGoogle);
    delRegistro(GestaoStorageKeys.AcessoUsuario);
  };

  //volta para o login
  const voltar = () => {
    history.replace('/configuracoes');
  };

  if (carregando.carregando) {
    return (
      <div className={classes.carregando}>
        <Carregando carregando={true} titulo={carregando.titulo} mensagem={carregando.mensagem} />
      </div>
    );
  }

  if (carregandoForm) {
    return (
      <div className={classes.carregando}>
        <Carregando carregando={true} titulo={carregando.titulo} mensagem={carregando.mensagem} />
      </div>
    );
  }


  //tinha vez q dava exception aqui só para previnir
  if (acesso !== null && acesso !== undefined && Object.entries(acesso).length !== 0) {
    if (isEmpty(acesso?.perfis[0]?.empresaId)) {
      return (
        <Grid item container xs={10} md={12} className={classes.container} justify="center">
          <TituloComBorda titulo="Bem Vindo!" />
          <form onSubmit={handleSubmit} className={classes.form}>
            <Button type="submit" className={classes.submit}>
              Deslogar
            </Button>
          </form>
        </Grid>
      );
    }
  }

  //se não houver módulo ativo
  if (token?.modulosObj?.filter((x) => x.IsAtivo).length === 0) {
    showErrorMessage('Erro ao selecionar sistema, motivo: a conta não possui nenhum módulo ativo');
    limparStorage();
    history.replace('/login');
  }

  function montaform() {
    if (empresa != null) {
      return (
        <DadosEmpresaForm
          textoBotao="SALVAR EMPRESA"
          empresa={empresa}
          voltar={voltar}
          avancar={postEmpresaApi}
          tipo={TipoForm.POST}
        />
      );
    } else {
      return null;
    }

  }


  return (
    <>
      <div className={classes.formContainer}>
        <TituloComBorda titulo={'Dados Comerciais'} />
        <Typography variant="body2" align="center">
          Perfeito! Complete agora os dados comerciais da sua empresa
        </Typography>
        <div>
          {
          montaform()
          }
        </div>
      </div>
    </>
  );
}
