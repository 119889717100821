import { useCallback } from 'react';
import { useApiGestao } from 'servicos/api-gestao/base';

export function useCadastrarContrato() {
  const { invocarApi, ...outrasPropriedades } = useApiGestao({
    config: {
      url: 'contrato',
      method: 'POST',
    },
  });

  const cadastrarContrato = useCallback(
    (dados: any) => {
      const dadosNormalizados = {
        ...dados,
        empresa: {
          ...dados.empresa,
          Cep: dados.empresa.Cep.replace(/\D+/g, ''),
          Doc: dados.empresa.Doc.replace(/\D+/g, ''),
          Email: dados.usuario.email
        },
        descricao: `Cadastro ${dados.empresa.Doc}`,
      };

      return invocarApi({
        data: dadosNormalizados,
        enviarTokenIntegracao: true,
        forcarTokenIntegracao: true
      });
    },
    [invocarApi],
  );

  return {
    ...outrasPropriedades,
    cadastrarContrato,
  };
}
