import { Modulo } from './Modulo';

export class TokenGestao {
  constructor(
    public contratoId: string,
    public usuarioId: string,
    public usuarioNome: string,
    public usuarioEmail: string,
    public token: string,
    public modulos: string,
    public modulosObj: Array<Modulo>,
    public exp: number = 0,
  ) {}

  // public getExpiracao(): Date {
  //   try{
  //     return new Date(this.exp);
  //   }catch(e){
  //     return new Date();
  //   }
  // }
}
