import React, { useState } from 'react';
import { useStyles } from './grid-modulo-styles';
import { GridModuloProps } from './grid-modulo-props';
import { Grid } from '@material-ui/core';
import { CardIconList } from 'componentes';
import { Acesso, CardIconModel, TiposModulo } from 'modelos';

import logoS2Pay from 'arquivos-estaticos/logos/logo-s2Pay.png';
import logoMenuVip from 'arquivos-estaticos/logos/logo-menuVip.png';
import SettingsIcon from '@material-ui/icons/Settings';






function switchImgEmpresa(modulo) {
  switch (modulo.Modulo) {
    case TiposModulo.PortalS2:
      return (
        logoS2Pay
      );
    case TiposModulo.MenuVip:
      return (
        logoMenuVip
      );
    default:
      return (
        SettingsIcon
      );
  }
};


export const GridModulo = ({ moduloList, clickFunc}: GridModuloProps) => {


  const classes = useStyles();
  const cardList = Array<CardIconModel>();

  moduloList.map((modulo) => {
    cardList.push(new CardIconModel(modulo.Nome, switchImgEmpresa(modulo), clickFunc));
  })
  return (
    <>
      <Grid item spacing={4} container direction="row" justify="center" wrap="wrap">
        <CardIconList cardList={cardList} clickFunc={clickFunc}></CardIconList>
      </Grid>
    </>
  );
};
