import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    background: 'transparent',
    maxWidth: '500px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: null,
    },
  },
  form: {
    width: '100%',
    marginTop: 20,
  },
  submit: {
    margin: '20px 0px',
  },
  imgButton: {
    width: '67px',
    height: '67px',
    objectFit: 'contain',
  },
  iconButton: {
    margin: '0px 5px',
    cursor: 'pointer',
  },

  pVoltar: {
    textAlign: 'left',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginRight: 'unset',
    },
  },
  opcoesLogin: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
}));
