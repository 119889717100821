import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { GestaoStorageKeys, useGestaoStorage, useQueryString } from 'utils';
import { Carregando, useNotificacoes } from 'componentes';
import { isEmpty } from 'lodash';
import { useLoginService } from 'servicos';



export function AuthIntegradorPagina() {
    
    const history = useHistory();

    const { showErrorMessage } = useNotificacoes();
    const { setRegistro, getRegistro } = useGestaoStorage();

    const [logTokenSucesso, setLogTokenSucesso] = useState<any>(undefined);

    const query = useQueryString();

    let destination: any;
    if (!isEmpty(query.get('destination'))) {
        destination = atob(query.get('destination') as string);
    } else{
        destination = '/';
    }

    if (!isEmpty(query.get('callback'))) {
        setRegistro(GestaoStorageKeys.CallbackAcao, query.get('callback'));
    } else {
        showErrorMessage('Dados de Integração não foram informados.');
    }
    const dominioParam = query.get('valDominio')
    if (!isEmpty(dominioParam) && parseInt(dominioParam || '0') === 1) {
        setRegistro(GestaoStorageKeys.ValidarDominio, query.get('valDominio'));
    }

    const { logarPorContaLogada } = useLoginService();

    const loginComToken = async () => {        
        const { message, sucesso } = await logarPorContaLogada(token, '', false);
        console.log(message, sucesso);
        if(!sucesso){
            try{
                let callback = getRegistro(GestaoStorageKeys.CallbackAcao);
                window.location.href = atob(callback).replace('@token', token);
            } catch(e){
                setTimeout(() => {
                    showErrorMessage('Dados de Integração não foram informados.');
                    window.location.href = '/login'
                }, 1000);
            }
        } else{
            setLogTokenSucesso(true);
        }
    }

    let token: any;
    useEffect(()=> {
        if (!isEmpty(query.get('token'))){ 
             token = query.get('token');
             loginComToken();
        } else {
            setLogTokenSucesso(false);
        }
    }, [logTokenSucesso]);

    if(logTokenSucesso === undefined){
        return (<Carregando titulo="Carregando"></Carregando>)
    } else if(logTokenSucesso === false){
        return (<Redirect to="/login"></Redirect>)
    } else if(logTokenSucesso === true){
        return (<Redirect to={destination}></Redirect>)
    } else {
        console.log('tacaindoaqui')
        return (<Carregando titulo="Carregando"></Carregando>)
    }


  
    
}

