import React, { FC, useState } from 'react';
import { useStyles } from './login-styles';
import {
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { SenhaInput, Carregando, TituloComBorda, TextoELink } from 'componentes';
import LoginGoogleImg from 'arquivos-estaticos/imagens/login-google.png';
import LoginFacebookImg from 'arquivos-estaticos/imagens/login-facebook.png';
import { useLoginLogica } from './login-logica';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { VariaveisAmbiente } from 'ambiente';
import { Redirect } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { SelecionarContas } from '../selecionar-contas/selecionar-contas-pagina';

export const LoginPagina: FC = () => {
  const classes = useStyles();

  const [mostrarContas, setMostrarContas] = useState(true);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    carregando,
    continuarCadastroSocial,
    quandoLoginSocialFalhar,
    quandoLoginGoogleForFeito,
    quandoLoginFacebookForFeito,
    startarLoginSocial,
    carregarContasLogadas,
    quandoLoginContaSalva,
    setManterConectado,
    manterConectado,
    values,
    token,
    contasLogadas,
    contasLogadasRef,
  } = useLoginLogica();

  if (continuarCadastroSocial) {
    return <Redirect to={`/login/novo-usuario`} />;
  }

  if (token) {
    return <Redirect to="/bem-vindo" />;
  }

  if (carregando.carregando) {
    return (
      <Carregando
        carregando={true}
        telaCheia={false}
        titulo={carregando.titulo}
        mensagem={carregando.mensagem}
      />
    );
  }

  const contaSelecionada = async (conta) => {
    let promisse = await quandoLoginContaSalva(conta);
    if (!promisse) {
      carregarContasLogadas();
    }
    setMostrarContas(false);
  };
  const voltarLogin = () => {
    carregarContasLogadas();
    setMostrarContas(false);
  };
  const changeManterConectado = (event: React.ChangeEvent<HTMLInputElement>) => {
    setManterConectado(event.target.checked);
  };
  if (contasLogadas.length > 0 && mostrarContas) {
    return (
      <SelecionarContas
        onContaSelecionada={contaSelecionada}
        voltar={voltarLogin}
      ></SelecionarContas>
    );
  }

  return (
    <Grid xs={10} container className={classes.container} justify="center">
      <TituloComBorda titulo="Entrar" />
      <TextoELink
        descricao="Olá bem vindo de volta! Informe seu e-mail para continuar ou caso não seja cadastrado,"
        linkDescricao="Cadastre-se aqui."
        para="/login/novo-usuario"
        juncao="espacar"
      />
      <form onSubmit={handleSubmit} autoComplete="off" className={classes.form}>
        <TextField
          label="Email"
          name="email"
          placeholder="usuario@empresa.com.br"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          error={Boolean(errors.email && touched.email)}
          helperText={errors.email && touched.email ? errors.email : undefined}
          autoFocus
        />
        <SenhaInput
          label="Senha"
          name="senha"
          placeholder="Digite sua senha aqui"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.senha}
          error={Boolean(errors.senha && touched.senha)}
          helperText={errors.senha && touched.senha ? errors.senha : undefined}
        />

        <br />

        <div className={classes.opcoesLogin}>
          <FormControlLabel
            className={classes.pVoltar}
            control={
              <Checkbox
                size="small"
                checked={manterConectado}
                onChange={changeManterConectado}
                name="checked"
                color="primary"
                style={{ color: 'primary', marginLeft:'0px' }}
              />
            }
            label={<span style={{ fontSize: '14px' }}>{'Mantenha-me conectado.'}</span>}
          />
          <TextoELink
            descricao="Esqueceu sua Senha?"
            linkDescricao="Clique aqui."
            para="/login/esqueci-a-senha"
            align="right"
            juncao="espacar"
          />
        </div>
        <br />
        <Grid container spacing={1}>
          {contasLogadasRef.current.length > 0 && (
            <Grid item xs={12} md={4}>
              <Button
                onClick={() => setMostrarContas(true)}
                tabIndex={10}
                color="secondary"
                startIcon={<ArrowBackIosIcon />}
                className={classes.submit}
              >
                Voltar
              </Button>
            </Grid>
          )}
          <Grid item xs={12} md={contasLogadasRef.current.length > 0 ? 8 : 12}>
            <Button
              type="submit"
              tabIndex={9}
              startIcon={<ExitToAppIcon />}
              className={classes.submit}
            >
              Acessar
            </Button>
          </Grid>
        </Grid>

        <Typography variant="body2" align="center" gutterBottom>
          Ou acesse utilizando
        </Typography>
        <Grid container justify="center">
          <GoogleLogin
            clientId={VariaveisAmbiente.googleAppId}
            render={(renderProps) => (
              <IconButton onClick={startarLoginSocial(renderProps)} className={classes.iconButton}>
                <img src={LoginGoogleImg} className={classes.imgButton} alt="Login Google" />
              </IconButton>
            )}
            onSuccess={quandoLoginGoogleForFeito}
            onFailure={quandoLoginSocialFalhar('Google')}
            cookiePolicy={'single_host_origin'}
          />
          <FacebookLogin
            appId={VariaveisAmbiente.facebookAppId}
            callback={quandoLoginFacebookForFeito}
            onFailure={quandoLoginSocialFalhar('Facebook')}
            render={(props) => (
              <IconButton onClick={startarLoginSocial(props)} className={classes.iconButton}>
                <img src={LoginFacebookImg} className={classes.imgButton} alt="Login Facebook" />
              </IconButton>
            )}
          />
        </Grid>
      </form>
    </Grid>
  );
};
