import React, { useCallback, useMemo } from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import { InputComMascaraProps } from './input-com-mascara-props';
import InputMask from 'react-input-mask';
import { merge } from 'lodash';

export function BaseInput({
  inputRef,
  naoMostrarMascaraComoCaracter,
  guiarUsuario,
  mascara,
  ...other
}: InputComMascaraProps & { inputRef: any }) {
  const obterReferenciaAposMontagemDoComponente = useCallback(
    (ref: any) => {
      inputRef(ref ? ref.inputElement : null);
    },
    [inputRef],
  );

  return (
    <InputMask
      {...other}
      ref={obterReferenciaAposMontagemDoComponente}
      mask={mascara}
      alwaysShowMask={naoMostrarMascaraComoCaracter}
      maskPlaceholder={guiarUsuario ? '_' : null}
    />
  );
}

export function InputComMascara({
  mascara,
  naoMostrarMascaraComoCaracter,
  guiarUsuario,
  ...props
}: InputComMascaraProps & TextFieldProps) {
  const defaultInputProps = useMemo(
    () => ({
      inputComponent: BaseInput,
      inputProps: {
        mascara,
        naoMostrarMascaraComoCaracter,
        guiarUsuario,
      },
    }),
    [naoMostrarMascaraComoCaracter, mascara, guiarUsuario],
  );

  return <TextField value={props.value} {...props} InputProps={merge(props.InputProps || {}, defaultInputProps)} />;
}
