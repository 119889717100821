import { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { isEmpty, merge } from 'lodash';
import { useUsuarioExiste, useEmpresaExiste, useContratoService, useLoginService } from 'servicos';
import { NovoUsuarioInicial, Steps, StepsEnum } from './novo-usuario-dados';
import { useConfirm } from 'material-ui-confirm';
import { useNotificacoes } from 'componentes';
import { CnpjValido } from 'validacao';
import { useConsultaCnpj } from 'servicos/webservice-saurus-utils/consulta-cnpj';
import { GestaoStorageKeys, useGestaoStorage } from 'utils';

export function useNovoUsuarioLogica() {
  const tema = useTheme();
  const history = useHistory();
  const { showErrorMessage, showWarningMessage } = useNotificacoes();
  const { verificarSeUsuarioExiste, carregando: verificandoSeUsuarioExiste } = useUsuarioExiste();
  const { verificarSeEmpresaExiste, carregando: verificandoSeEmpresaExiste } = useEmpresaExiste();
  const { consultarCNPJ, carregando: verificandoCNPJSEFAZ } = useConsultaCnpj();
  const { getRegistro, exists, delRegistro } = useGestaoStorage();
  const [carregandoOutros, setarCarregandoOutros] = useState({
    mensagem: 'Por favor aguarde enquanto carregamos os componentes',
    titulo: 'Carregando',
    carregando: true,
  });
  const { realizarLogin, carregando: verificandoLogin } = useLoginService();
  const {
    retornarPreContrato,
    gerarContrato,
    carregandoTela: carregandoTelaContrato,
  } = useContratoService();

  const dispositivoMovel = useMediaQuery(tema.breakpoints.down('md'));
  const [stepAtual, setarStep] = useState(Steps.Inicial);
  const [dados, setarDados] = useState(NovoUsuarioInicial);
  const confirm = useConfirm();

  useEffect(() => {
    try {
      if (exists(GestaoStorageKeys.TokenSocialFacebook)) {
        let facebookToken = getRegistro(GestaoStorageKeys.TokenSocialFacebook);

        setarDados((dadoAnterior) =>
          merge(dadoAnterior, {
            usuario: {
              nome: facebookToken.name,
              userFacebook: facebookToken.id,
              facebookToken: facebookToken,
            },
          }),
        );
      } else if (exists(GestaoStorageKeys.TokenSocialGoogle)) {
        let googleToken = getRegistro(GestaoStorageKeys.TokenSocialGoogle);

        setarDados((dadoAnterior) =>
          merge(dadoAnterior, {
            usuario: {
              nome: googleToken.profileObj.name,
              email: googleToken.profileObj.email,
              usegGoogle: googleToken.profileObj.email,
              googleToken: googleToken,
            },
          }),
        );
      }
    } catch (e) {
      showWarningMessage(`Erro ao identificar o acesso social realizado. Detalhe: ${e.message}`);
    } finally {
      setarCarregandoOutros({ carregando: false, titulo: '', mensagem: '' });
      setarStep(Steps.CadastrarUsuario);
    }
  }, [getRegistro, showWarningMessage, exists]);

  const limparSocial = useCallback(() => {
    if (exists(GestaoStorageKeys.TokenSocialFacebook))
      delRegistro(GestaoStorageKeys.TokenSocialFacebook);
    if (exists(GestaoStorageKeys.TokenSocialGoogle))
      delRegistro(GestaoStorageKeys.TokenSocialGoogle);
  }, [delRegistro, exists]);

  const limparSocialUsuario = useCallback(() => {
    limparSocial();
    setarDados((dadoAnterior) => ({
      ...dadoAnterior,
      usuario: {
        ...dadoAnterior.usuario,
        nome: '',
        userGoogle: '',
        googleToken: '',
        userFacebook: '',
        facebookToken: '',
        senha: '',
        email: '',
      },
    }));
  }, [limparSocial]);

  const avancarStepUsuario = useCallback(
    async (usuario) => {
      setarDados((dadoAnteriror) => ({
        ...dadoAnteriror,
        usuario,
      }));

      const { erro, statusCode } = await verificarSeUsuarioExiste(usuario.email);

      if (erro) {
        if (statusCode !== 410) {
          showErrorMessage(erro.message + ' - ' + statusCode);
        } else {
          setarStep(Steps.ConsultarEmpresa);
        }
        return;
      }

      confirm({
        title: 'Já está cadastrado?',
        description:
          'O e-mail informado já consta em nossa base de usuários. Não será possível prosseguir com este e-mail. Selecione uma ação abaixo: ',
        cancellationText: 'Trocar o e-mail',
        confirmationText: 'Recuperação de Senha',
      }).then(() => {
        limparSocial();
        history.replace('/login/esqueci-a-senha');
      });
    },
    [history, confirm, verificarSeUsuarioExiste, showErrorMessage, limparSocial],
  );

  const avancarStepPesquisarEmpresa = useCallback(
    async (dominio: string, cnpj: string, uf: string) => {
      try {
        dados.empresa.Doc = '';
        let dominioTratado = dominio;
        if (CnpjValido(dominio)) {
          dominioTratado = dominio.replace(/\D/g, '');
        }
        if (CnpjValido(dominio)) {
          const { erro } = await verificarSeEmpresaExiste(dominioTratado);
          if (!erro) {
            dados.empresa.Doc = dominio;
            setarStep(Steps.PedirConvite);
            return;
          }
        } else if (CnpjValido(cnpj)) {
          const { erro } = await verificarSeEmpresaExiste(cnpj);
          if (!erro) {
            dados.empresa.Doc = cnpj;
            setarStep(Steps.PedirConvite);
            return;
          }
        }

        let consultaDom = isEmpty(dominioTratado) ? cnpj.replace(/\D/g, '') : dominioTratado;
        const { contrato, sucesso, message } = await retornarPreContrato(consultaDom);
        if (!sucesso) {
          showWarningMessage(message);
          return;
        }

        setarDados((dadoAnterior) =>
          merge(dadoAnterior, {
            dominio: consultaDom,
            empresa: {
              Doc: cnpj || dominioTratado,
              UF: uf,
            },
          }),
        );

        if (contrato != null && contrato.empresas != null && contrato.empresas.length > 0) {
          try {
            let cep = '';
            if (contrato.empresas[0].cep != null) {
              cep = contrato.empresas[0].cep.toString();
              if (cep.length === 8) {
                cep = cep.substr(0, 5) + '-' + cep.substr(5, 3);
              }
            }
            setarDados((dadoAnterior) =>
              merge(dadoAnterior, {
                dominio: consultaDom,
                empresa: {
                  Doc: contrato.empresas[0].doc,
                  IE: contrato.empresas[0].ie,
                  Nome: contrato.empresas[0].nome,
                  Fant: contrato.empresas[0].fant,
                  Contato: contrato.empresas[0].contato,
                  Cep: cep,
                  Lgr: contrato.empresas[0].lgr,
                  Nro: contrato.empresas[0].nro,
                  Cpl: contrato.empresas[0].cpl,
                  Bairro: contrato.empresas[0].bairro,
                  Municipio: contrato.empresas[0].municipio,
                  UF: contrato.empresas[0].uf,
                  Pais: contrato.empresas[0].pais,
                  CodMun: contrato.empresas[0].codMun,
                  CodPai: contrato.empresas[0].codPais,
                  Telefone: contrato.empresas[0].telefone,
                  Email: dados.usuario.email,
                  Site: contrato.empresas[0].site,
                  CRT: contrato.empresas[0].crt,
                  GMT: contrato.empresas[0].gmt,
                  CNAE: contrato.empresas[0].cnae,
                  InfAdic: contrato.empresas[0].infAdic,
                },
              }),
            );
          } catch (e) {
            showErrorMessage(
              `Erro ao preencher os dados da Empresa localizada. Tente novamente em alguns instantes. Detalhe: ` +
                e.message,
            );
          }
        } else {
          try {
            //CONSULTAR NO WSMASTER PARA LOCALIZAR NA SEFAZ
            const result = await consultarCNPJ(dados.empresa.Doc, dados.empresa.UF);

            if (result?.sucesso) {
              let cep = result?.dados.retConsultaDocumento.CadastroLocalizado._CEP;
              if (cep.length === 8) {
                cep = cep.substr(0, 5) + '-' + cep.substr(5, 3);
              }

              setarDados((dadoAnterior) =>
                merge(dadoAnterior, {
                  empresa: {
                    ...dados.empresa,
                    IE: result?.dados.retConsultaDocumento.CadastroLocalizado._IE,
                    Nome: result?.dados.retConsultaDocumento.CadastroLocalizado._xNome,
                    Fant: result?.dados.retConsultaDocumento.CadastroLocalizado._xNome,
                    Cep: cep,
                    Lgr: result?.dados.retConsultaDocumento.CadastroLocalizado._xLgr,
                    Nro: result?.dados.retConsultaDocumento.CadastroLocalizado._nro,
                    Cpl: result?.dados.retConsultaDocumento.CadastroLocalizado._xCpl,
                    Bairro: result?.dados.retConsultaDocumento.CadastroLocalizado._xBairro,
                    Municipio: result?.dados.retConsultaDocumento.CadastroLocalizado._xMun,
                    UF: result?.dados.retConsultaDocumento.CadastroLocalizado._UF,
                    Pais: 'Brasil',
                    CodMun: result?.dados.retConsultaDocumento.CadastroLocalizado._cMun,
                    CodPai: 1058,
                    CRT:
                      result?.dados.retConsultaDocumento.CadastroLocalizado._RegApuracao ===
                      'SIMPLES NACIONAL'
                        ? 1
                        : 3,
                    CNAE: result?.dados.retConsultaDocumento.CadastroLocalizado._CNAE,
                    Site: '',
                    Telefone: '',
                  },
                }),
              );
            } else {
              showWarningMessage(
                'O Documento informado não foi localizado na SEFAZ. Preencha manualmente os campos a seguir.',
              );
            }
          } catch (e) {
            showWarningMessage(
              `Erro ao localizar o CNPJ informado na SEFAZ. Detalhe: ${e.message}`,
            );
          }
        }
        setarStep(Steps.CadastrarEmpresa);
      } catch (e) {
        showErrorMessage('Erro ao confirmar a empresa. Detalhe: ' + e.message);
      }
    },
    [
      verificarSeEmpresaExiste,
      consultarCNPJ,
      dados.empresa,
      dados.usuario.email,
      retornarPreContrato,
      showErrorMessage,
      showWarningMessage,
    ],
  );

  const avancarStepCadastrarEmpresa = useCallback(
    async (empresa: any) => {
      const { contrato, message } = await gerarContrato({
        ...dados,
        empresa: merge(dados.empresa, empresa),
      });

      if (contrato) {
        setarStep(Steps.Sucesso);
        return;
      }
      showErrorMessage(message);
    },
    [gerarContrato, dados, showErrorMessage],
  );

  const acessarEmpresa = useCallback(async () => {
    limparSocial();

    const { message, sucesso } = await realizarLogin(
      dados.usuario.email,
      dados.usuario.senha,
      null,
      null,
      false,
    );
    if (sucesso === false) {
      showErrorMessage(message);
    }

    setarCarregandoOutros({
      carregando: true,
      titulo: 'Bem Vindo!',
      mensagem: 'Aguarde mais um instante que estamos redirecionando você para o sistema...',
    });
    setTimeout(() => {
      history.replace('/bem-vindo');
    }, 1000);
  }, [
    history,
    dados.usuario.email,
    dados.usuario.senha,
    limparSocial,
    realizarLogin,
    showErrorMessage,
  ]);

  const voltarLogin = useCallback(() => {
    limparSocial();
    history.replace('/login');
  }, [limparSocial, history]);

  const voltarStep = useCallback(
    (stepId: StepsEnum) => () => {
      const stepKey = Object.keys(Steps).find((key) => Steps[key].id === stepId);

      if (stepKey) {
        if (Steps[stepKey] === Steps.Inicial) {
          voltarLogin();
        } else {
          setarStep(Steps[stepKey]);
        }
      }
    },
    [voltarLogin],
  );

  const carregando = {
    carregando:
      verificandoSeUsuarioExiste ||
      verificandoSeEmpresaExiste ||
      verificandoCNPJSEFAZ ||
      carregandoTelaContrato.carregando ||
      verificandoLogin.carregando ||
      carregandoOutros.carregando,

    mensagem: carregandoTelaContrato.carregando
      ? carregandoTelaContrato.mensagem
      : verificandoLogin.carregando
      ? verificandoLogin.mensagem
      : carregandoOutros.carregando
      ? carregandoOutros.mensagem
      : verificandoSeUsuarioExiste
      ? 'Localizando o e-mail informado em nossa base de dados...'
      : verificandoCNPJSEFAZ
      ? 'Localizando o CNPJ informado na SEFAZ'
      : 'Localizando CNPJ informado em nossa base de dados...',

    titulo: carregandoTelaContrato.carregando
      ? carregandoTelaContrato.titulo
      : verificandoLogin.carregando
      ? verificandoLogin.titulo
      : carregandoOutros.carregando
      ? carregandoOutros.titulo
      : verificandoSeUsuarioExiste
      ? 'Validando Usuário'
      : verificandoCNPJSEFAZ
      ? 'Procurando na SEFAZ'
      : 'Validando CNPJ',
  };

  return {
    carregando,
    stepAtual,
    dispositivoMovel,
    limparSocialUsuario,
    avancarStepUsuario,
    avancarStepPesquisarEmpresa,
    avancarStepCadastrarEmpresa,
    acessarEmpresa,
    voltarLogin,
    voltarStep,
    dados,
  };
}
