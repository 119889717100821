import React, { useEffect, useCallback } from 'react';
import useState from 'react-usestateref';
import { useStyles } from './selecionar-contas-styles';

import {
  Grid,
  Typography,
  IconButton,
  Card,
  CardContent,
  CardActionArea,
  Tooltip,
  Fab,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { TiposLogin, UserAccounts } from 'utils/users-accounts';
import { TituloComBorda } from 'componentes';
import LoginGoogleImg from 'arquivos-estaticos/imagens/login-google.png';
import LoginFacebookImg from 'arquivos-estaticos/imagens/login-facebook.png';
import { GestaoStorageKeys, useGestaoStorage } from 'utils';
import { isEmpty } from 'lodash';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useResources } from 'resources/resources';

const resources = useResources();


export function SelecionarContas(props: any) {
  const classes = useStyles();
  const { getRegistro } = useGestaoStorage();
  //usamos o Ref para acessar o último estado,pois o use state normal é assíncrono
  const [contasLogadas, setContasLogadas, contasLogadasRef] = useState([]);
  const { getLoggedUsers, deleteUser } = UserAccounts();
  const urlVoltar = getRegistro(GestaoStorageKeys.CallbackAcao) || '';

  useEffect(() => {
    carregarLogados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const removerUsuario = (event, id) => {
    event.stopPropagation();
    deleteUser(id);
    carregarLogados();
  };
  const voltarUrl = useCallback(() => {
    const url = new URL(atob(urlVoltar).replace('@token', ''));
    url.searchParams.append('loginCancelado', 'true');
    window.location.href = url.href;
    return;
  }, [urlVoltar]);
  const voltar = () => props.voltar();
  const carregarLogados = () => {
    setContasLogadas(getLoggedUsers());

    if (contasLogadasRef.current.length < 1) {
      voltar();
    }
  };

  const switchImgLogIn = (tipo) => {
    switch (tipo) {
      case TiposLogin.Google:
        return LoginGoogleImg;
      case TiposLogin.Facebook:
        return LoginFacebookImg;
      case TiposLogin.Manual:
        return resources.gravatarTemp;
      default:
        return resources.gravatarTemp;;
    }
  };
  return (
    <>
      <Grid xs={10} container direction="column" justify="center" className={classes.container}>
        <TituloComBorda titulo="Contas Conectadas">
          {!isEmpty(urlVoltar) ? (
            <Tooltip title="Voltar" placement="top">
              <Fab color="secondary" aria-label="voltar" size="small" className={classes.pVoltar}>
                <div className={classes.btnTooltip}>
                  <ArrowBackIosIcon className={classes.iconBtn} onClick={voltarUrl} />
                </div>
              </Fab>
            </Tooltip>
          ) : null}
        </TituloComBorda>
        {contasLogadas.map((conta: any) => (
          <Card key={conta.id} className={classes.card}>
            <CardActionArea>
              <CardContent onClick={() => props.onContaSelecionada(conta)}>
                <div className={classes.cardContent}>
                  <img
                    src={switchImgLogIn(conta.tipo)}
                    className={classes.imgButton}
                    alt="iconLogin"
                  />
                  <div className={classes.dadosUsuario}>
                    <Typography color="textSecondary">{conta.email}</Typography>
                    <Typography variant="h5" component="h2">
                      {conta.nome}
                    </Typography>
                  </div>
                  <div className={classes.buttonClose}>
                    <IconButton
                      aria-label="times"
                      onClick={(event) => removerUsuario(event, conta.id)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}

        <Card className={classes.card} onClick={() => voltar()}>
          <CardActionArea>
            <CardContent>
              <div className={classes.outraConta}>
                <PersonAddIcon className={classes.imgButton} />
                <Typography variant="h5" component="h2">
                  Outra Conta
                </Typography>
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </>
  );
}
