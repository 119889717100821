import * as Yup from 'yup';
import { CnpjDominioValido, CnpjValido } from 'validacao';

export const PesquisarEmpresaDominioFormValidacao = validarForm(true);
export const PesquisarEmpresaFormValidacao = validarForm(false);

export function validarForm(isDominio: boolean) {

  //SE VEIO DE CALLBACK PODE SER ASSOCIADO A UM DOMINIO
  if (isDominio) {
    return Yup.object().shape({
      dominio: Yup.string()
        .required('O Domínio (Saurus) da empresa é obrigatório.')
        .test('cnpj', 'Informe um cnpj ou domínio válido!', CnpjDominioValido),
    });

  } else {

    return Yup.object().shape({
      doc: Yup.string().required('O CNPJ é obrigatório.').test('cnpj', 'O CNPJ está inválido.', CnpjValido),
      uf: Yup.string().required('UF inválida.')
    });

  }

}
