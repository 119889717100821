import axios from 'axios';
import { useCallback } from 'react';
import { useApiGestao } from 'servicos/api-gestao/base';

export function useCadastrarEmpresa() {
  const { invocarApi, ...outrasPropriedades } = useApiGestao({
    config: {
      url: 'empresa',
      method: 'POST',
    },
  });

  const postEmpresa = useCallback(
    (dados: any, token: any) => {
      const dadosNormalizados = {
        ...dados,
      };
      
      // return axios({
      //   method: 'POST',
      //   url:'https://apisaurusgestao-master.azurewebsites.net/api/empresa',
      //   headers: {
      //     Authorization: 'bearer ' + token
      //   }, 
      //   data: dados
      // })

      return invocarApi({
        data: dadosNormalizados,
        enviarTokenUsuario: true
        });
    },
    [invocarApi],
  );

  return {
    ...outrasPropriedades,
    postEmpresa,
  };
}
