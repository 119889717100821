import React from 'react';
import { Grid, Typography, Link } from '@material-ui/core';
import { useStyles } from './dados-usuario-form-styles';
import LoginGoogleImg from 'arquivos-estaticos/imagens/login-google.png';
import LoginFacebookImg from 'arquivos-estaticos/imagens/login-facebook.png';
import { isEmpty } from 'lodash';

export const DadosUsuarioSocial = ({ facebookToken, googleToken, limparSocial }) => {
    const classes = useStyles();
    if (!isEmpty(facebookToken)) {
        return (
            <Grid className={classes.social} xs={12} >
                <img src={LoginFacebookImg} className={classes.imgSocial} alt="Login Facebook" />
                <Typography variant={'body2'}>
                    Vinculado ao facebook de <b> {facebookToken.name} </b> ({facebookToken.id})
                            </Typography>
                < Link onClick={(async () => {
                    limparSocial()
                })
                } className={classes.linkSocial} color="primary" >
                    <strong>Desvincular</strong>
                </Link>
            </Grid>
        );

    } else if (!isEmpty(googleToken)) {
        return (
            <Grid className={classes.social} xs={12} >
                <img src={LoginGoogleImg} className={classes.imgSocial} alt="Login Google" />
                <Typography variant={'body2'} >
                    Vinculado ao google de <b> {googleToken.profileObj.name} </b> ({googleToken.profileObj.email})
                                </Typography>
                <Link onClick={(async () => {
                    limparSocial()
                })
                } className={classes.linkSocial} color="primary" >
                    <strong>Desvincular</strong>
                </Link>
            </Grid>
        );
    }
    return null;
}