import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },

  submit: {
    margin: '20px 0px',
  },
  social: {
    marginTop:40,
    marginBottom:10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border:'1px solid rgba(0,0,0,0.1)',
    padding:5,
    borderRadius:4
  },
  
  imgSocial: {
    width: '40px',
    height: '40px',
    marginRight:10,
  },
  
  linkSocial:{
    marginLeft:10,
    cursor:'pointer'
  },
  espacarDireitaInput: {
    paddingRight: '0px',
    [theme.breakpoints.up('md')]: {
      paddingRight: '10px',
    },
  },
  acoesContainer: {
    marginTop: '10px',
  },
  marginDireita: {
    marginRight: '10px',
  },
}));
