import React from 'react';
import { InputComMascara } from 'componentes/inputs/input-com-mascara';
import { CircularProgress, IconButton, InputAdornment, TextFieldProps } from '@material-ui/core';
import { BuscarCnpjInputProps } from './cnpj-input-props'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { useConsultaCnpj } from 'servicos/webservice-saurus-utils/consulta-cnpj';


const mascara = [
  /\d+/,
  /\d+/,
  '.',
  /\d+/,
  /\d+/,
  /\d+/,
  '.',
  /\d+/,
  /\d+/,
  /\d+/,
  '/',
  /\d+/,
  /\d+/,
  /\d+/,
  /\d+/,
  '-',
  /\d+/,
  /\d+/,
];

export function CnpjInput(props: BuscarCnpjInputProps) {

  const { consultarCNPJ, carregando } = useConsultaCnpj();


  const { UF, quandoEncontrarUmCnpjExecutar, hasButton, ...outros } = props;
  return (
    <InputComMascara
      {...outros}
      guiarUsuario={false}
      naoMostrarMascaraComoCaracter={true}
      mascara={mascara}
      InputProps={{
        endAdornment: hasButton ? (
          <InputAdornment position="end">
            <IconButton
              onClick={async (event) => {
                const result = await consultarCNPJ(props.value as string, UF as string);
                console.log(result);
                if (result !== null || result !== undefined)
                  if (result.sucesso && quandoEncontrarUmCnpjExecutar !== undefined) {
                    quandoEncontrarUmCnpjExecutar(result.dados.retConsultaDocumento.CadastroLocalizado);
                  }
              }}
              disabled={props.disabled}
              edge="end"
            >
              {carregando ? <CircularProgress size={25} /> : <SearchRoundedIcon />}
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
    />
  );
}
