import parse from 'html-react-parser';
import { files } from 'arquivos-estaticos';
import {IResources} from './interface-resources'
import { AmbientePadrao, VariaveisAmbiente } from 'ambiente';
import { SaurusTema } from 'temas';

/* eslint-disable no-eval */
const resCardapioSaurus = () => {
  const fileReader = files();
  const getObject = () => {
    return {
      nome: 'MenuVip',
      tituloPagina: 'MenuVip Auth - Bem Vindo!',
      theme: SaurusTema,
      favIcon: fileReader.loadFavIcon('menuVip', 'icon.png'),
      logo: fileReader.loadLogo('menuVip', 'logo.png'),
      // Remover ao implementar o upload de gravatar - Vinicius Serafim 28-06-2021
      gravatarTemp: fileReader.loadGravatar('menuVip', 'icon.png'),
      footer: parse(`MenuVip © - Copyright ${new Date().getFullYear()} - Todos os direitos reservados.` + (VariaveisAmbiente.isDev ? " - DEV" : ""))
    }as IResources;
  };

  return { getObject };
};

export const menuVip = resCardapioSaurus();
/* eslint-enable no-eval */
