import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './titulo-com-borda-styles';
import { TituloComBordaProps } from './titulo-com-borda-props';

export const TituloComBorda = ({ titulo, children }: TituloComBordaProps) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.alignCenter}>
        <div className={classes.container}>
          <div> {children}</div>
          <Typography variant="h4" className={classes.titulo} align="center">
            {titulo}
          </Typography>
        </div>
        <div className={classes.border} />
      </div>
    </>
  );
};
