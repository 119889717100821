import { createMuiTheme } from '@material-ui/core';

export const SaurusTema = createMuiTheme({
  palette: {
    primary: {
      main: '#0E72AC',
      light: '#57a0de',
      dark: '#00477c',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#555555',
      light: '#818181',
      dark: '#2c2c2c',
      contrastText: '#ffffff',
    },
    background: {
      default: '#F5F5F5',
      paper: '#f5f5f5',
    },
    text: {
      primary: '#000000',
      secondary: '#555555',
    },
  },
  props: {
    MuiTextField: {
      variant: 'outlined',
      autoComplete: 'off',
      fullWidth: true,
      margin: 'normal',
      InputLabelProps: {
        shrink: true,
      },
      style: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
      },
    },
    MuiButton: {
      variant: 'contained',
      disableElevation: true,
      color: 'primary',
      autoCapitalize: 'none',
      fullWidth: true,
    },
  },
});
