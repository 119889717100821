import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

export function useNotificacoes() {
  const { enqueueSnackbar } = useSnackbar();

  const showMessage = useCallback(
    (message, type = undefined) => {
      enqueueSnackbar(message, {
        variant: type,
      });
    },
    [enqueueSnackbar],
  );

  const showWarningMessage = useCallback((message) => showMessage(message, 'warning'), [
    showMessage,
  ]);

  const showSuccessMessage = useCallback((message) => showMessage(message, 'success'), [
    showMessage,
  ]);

  const showInfoMessage = useCallback((message) => showMessage(message, 'info'), [showMessage]);

  const showErrorMessage = useCallback((message) => showMessage(message, 'error'), [showMessage]);

  return {
    showMessage,
    showErrorMessage,
    showSuccessMessage,
    showInfoMessage,
    showWarningMessage,
  };
}
