import React from 'react';
import { Redirect } from 'react-router-dom';
import { converterTokenGestao, GestaoStorageKeys, useGestaoStorage } from 'utils';
import { Carregando, useNotificacoes } from 'componentes';

export function BemVindoPagina() {
  const { showErrorMessage } = useNotificacoes();
  const { delRegistro, exists, getRegistro } = useGestaoStorage();

  const token = converterTokenGestao(getRegistro(GestaoStorageKeys.TokenGestao));


  if (token) {
    if (exists(GestaoStorageKeys.CallbackAcao)) {
      let callback = getRegistro(GestaoStorageKeys.CallbackAcao);
      setTimeout(() => {
        delRegistro(GestaoStorageKeys.CallbackAcao);
        delRegistro(GestaoStorageKeys.ValidarDominio);
        delRegistro(GestaoStorageKeys.TokenGestao);
        delRegistro(GestaoStorageKeys.AcessoUsuario);

        //NAO TINHA ISSO MAS O FACEBOOK TAVA FICANDO LOGANDO SEMPRE
        delRegistro(GestaoStorageKeys.TokenSocialFacebook);
        delRegistro(GestaoStorageKeys.TokenSocialGoogle);
        window.location.href = atob(callback).replace('@token', token.token);
      }, 2000);
      return (
        <Carregando
          carregando={true}
          telaCheia={false}
          titulo={`Olá ${token.usuarioNome}!`}
          mensagem={'Aguarde apenas mais um instante para iniciar sua sessão...'}
        />
      );
    } else {
      return <Redirect to="/" />;
    }
  }

  showErrorMessage('Não foi possíve identificarmos os dados de acesso.');
  return <Redirect to="/login" />;
}
