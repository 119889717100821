import { CssBaseline, ThemeProvider } from '@material-ui/core';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { SaurusTema } from './temas';
import { Rotas } from './rotas';
import { Notificacoes } from 'componentes';
import { ConfirmProvider } from 'material-ui-confirm';
import { DocumentTitle } from 'componentes/document-title';
import { useResources } from 'resources';

const resources = useResources();

ReactDOM.render(
  <React.StrictMode>
    <DocumentTitle></DocumentTitle>
    <ThemeProvider theme={resources.theme}>
      <CssBaseline>
        <Notificacoes>
          <ConfirmProvider
            defaultOptions={{
              title: 'Você tem certeza ?',
              cancellationButtonProps: {
                variant: 'text',
                color: 'secondary',
              },
              confirmationButtonProps: {
                variant: 'text',
              },
              confirmationText: 'Sim',
              cancellationText: 'Não',
            }}
          >
            <Rotas />
          </ConfirmProvider>
        </Notificacoes>
      </CssBaseline>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
