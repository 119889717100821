import React, { useCallback, useEffect, useState } from 'react';
import { InputComMascara } from 'componentes/inputs/input-com-mascara';
import { InputAdornment, IconButton, CircularProgress } from '@material-ui/core';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { useConsultaCep } from 'servicos';
import { BuscarCepInputProps } from './cep-input-props';
import { BuscarCepResultado } from 'modelos';

const mascara = [/\d+/, /\d+/, /\d+/, /\d+/, /\d+/, '-', /\d+/, /\d+/, /\d+/];

export function CepInput(props: BuscarCepInputProps) {
  const { onChange, quandoEncontrarUmCepExecutar, ...outros } = props || {};

  const handleMouseDownPassword = useCallback((event) => event.preventDefault(), []);
  const { carregando, consultarCep } = useConsultaCep();
  const [cepValue, setarValue] = useState<any>(props.value);
  
  React.useEffect(() => {
    setarValue(props.value);
}, [props.value])


  return (
    <InputComMascara
      {...outros}
      guiarUsuario={false}
      naoMostrarMascaraComoCaracter={true}
      mascara={mascara}
      value={cepValue}
      onChange={(event) => {
        setarValue(event.target.value);
        if (onChange) {

          onChange(event);
        }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={async (event) => {
                const result = await consultarCep(cepValue);
                if (result?.sucesso && quandoEncontrarUmCepExecutar) {
                  quandoEncontrarUmCepExecutar(result?.dados as BuscarCepResultado);
                }
              }}
              onMouseDown={handleMouseDownPassword}
              disabled={carregando}
              edge="end"
            >
              {carregando ? <CircularProgress size={25} /> : <SearchRoundedIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
