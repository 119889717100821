import { useQueryString } from 'utils';
import { useCallback, useState } from 'react';
import { useNotificacoes } from 'componentes';
import { useLoginService } from 'servicos';

export function useAtualizarSenhaLogica() {
  const query = useQueryString();
  const token = query?.get('token') || '';
  const email = atob(query?.get('email') || '');
  const { showSuccessMessage, showErrorMessage } = useNotificacoes();
  const { carregando, alterarSenha } = useLoginService();
  const [sucessoTrocaSenha, setSucessoTrocaSenha] = useState(false);
  const atualizarSenha = useCallback(
    async (values: any) => {
      const { message, sucesso } = await alterarSenha(token, email, values.senha);
      if (!sucesso)
        showErrorMessage(message);
      else if (sucesso) {
        showSuccessMessage('Senha alterada com sucesso! Realize o login para continuar.');
      }
      setSucessoTrocaSenha(sucesso);
    },
    [alterarSenha, token, email,showSuccessMessage,showErrorMessage],
  );



  return {
    token,
    email,
    atualizarSenha,
    sucessoTrocaSenha,
    carregando,
  };
}
