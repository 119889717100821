import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  containerTelaCheia: {
    background: 'rgba(255, 255, 255, 0.8)',
    zIndex: 9999999,
    height: '100%',
    width: '100%',
    position: 'fixed',
    margin: '0',
    padding: '0',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
  },
  spinner: {
    marginBottom: '15px',
  },
}));
