import React from 'react';
import { Button, Grid, TextField } from '@material-ui/core';
import { useStyles } from './pesquisar-empresa-form-styles';
import { CnpjInput, UfSelect } from 'componentes';
import { PesquisarEmpresaFormProps } from './pesquisar-empresa-form-props';
import { PesquisarEmpresaFormValidacao, PesquisarEmpresaDominioFormValidacao } from './pesquisar-empresa-form-validacao';
import { useFormik } from 'formik';
import { GestaoStorageKeys, useGestaoStorage } from 'utils';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';

export const PesquisarEmpresaForm: React.FC<PesquisarEmpresaFormProps> = ({
  dominio,
  doc,
  uf,
  avancar,
  voltar,
}) => {
  const { exists } = useGestaoStorage();

  const classes = useStyles();
  const { handleSubmit, handleBlur, handleChange, setFieldValue, values, errors, touched } = useFormik({
    initialValues: {
      dominio,
      doc,
      uf,
    },
    validationSchema: !exists(GestaoStorageKeys.ValidarDominio) ? PesquisarEmpresaFormValidacao : PesquisarEmpresaDominioFormValidacao,
    onSubmit: (valores: any) => { avancar(valores.dominio, valores.doc, valores.uf) },
  });

  let txtDominio = (
    <Grid container xs={12}>
      <Grid item xs={12} sm={8}>
        <CnpjInput
          label="Informe o CNPJ"
          className={classes.espacarDireitaInput}
          name="doc"
          value={values.doc}
          placeholder="Digite seu CNPJ aqui."
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(errors.doc && touched.doc)}
          helperText={errors.doc && touched.doc ? errors.doc : undefined}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <UfSelect
          {...
          {
            label: 'UF',
            placeholder: 'UF',
            name: 'uf',
            error: Boolean(errors.uf && touched.uf),
            helperText: Boolean(errors.uf && touched.uf) ? errors.uf : undefined,
          }}
          onBlur={handleBlur}
          ufValue={values.uf}
          onInputChange={(event, newValue) => {
            if (event) {
              setFieldValue('uf', newValue, true);
            }
          }}
        />
      </Grid>
    </Grid >);
  if (exists(GestaoStorageKeys.ValidarDominio)) {
    txtDominio = (
      <TextField
        label="Informe o Domínio ou CNPJ"
        name="dominio"
        value={values.dominio}
        placeholder="Digite seu Domínio ou CNPJ aqui."
        onChange={handleChange}
        onBlur={handleBlur}
        error={Boolean(errors.dominio && touched.dominio)}
        helperText={errors.dominio && touched.dominio ? errors.dominio : undefined}
      />
    );
  }

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      {txtDominio}
      <Grid container className={classes.acoesContainer} wrap="nowrap">
        <Button onClick={voltar} color="secondary" startIcon={<ArrowBackIos />} className={classes.marginDireita} type="button">
          Voltar
        </Button>
        <Button type="submit" startIcon={<ArrowForwardIos />}>Continuar</Button>
      </Grid>

    </form>
  );
};
