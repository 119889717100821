import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { TituloComBorda, TextoELink, SenhaInput, Carregando } from 'componentes';
import { useStyles } from './atualizar-senha-styles';
import { useAtualizarSenhaLogica } from './atualizar-senha-logica';
import { useFormik } from 'formik';
import { AtualizarSenhaValidacao } from './atualizar-senha-validacao';
import { useNotificacoes } from 'componentes';
import { Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
export function AtualizarSenha() {
  const classes = useStyles();
  const { showErrorMessage } = useNotificacoes();
  const { token, email, atualizarSenha, carregando, sucessoTrocaSenha } = useAtualizarSenhaLogica();
  const { handleSubmit, handleBlur, values, handleChange, errors, touched } = useFormik({
    initialValues: {
      senha: '',
      confirmacao: '',
    },
    validationSchema: AtualizarSenhaValidacao,
    onSubmit: atualizarSenha,
  });

  if (isEmpty(email) || isEmpty(token)) {
    showErrorMessage('Parâmetros necessários para redefinição de senha não foram informados.');
    return <Redirect to="/login" />;
  }
  if (sucessoTrocaSenha) {
    return <Redirect to="/login" />;
  }

  if (carregando.carregando) {
    return (
      <Carregando carregando={true} titulo={carregando.titulo} mensagem={carregando.mensagem} />
    );
  }

  return (
    <Grid container xs={10} className={classes.container} justify="center">
      <TituloComBorda titulo={`Atualizar Senha`} />
      <TextoELink
        descricao={`Informe a nova senha para o e-mail ${email} nos campos abaixo ou, caso tenha lembrado,`}
        linkDescricao="Clique aqui."
        juncao="espacar"
        para="/login"
      />
      <form onSubmit={handleSubmit} className={classes.form}>
        <SenhaInput
          name="senha"
          value={values.senha}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(errors.senha && touched.senha)}
          helperText={Boolean(errors.senha && touched.senha) && errors.senha}
          label="Senha"
          placeholder="Digite uma nova senha"
        />
        <SenhaInput
          name="confirmacao"
          value={values.confirmacao}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(errors.confirmacao && touched.confirmacao)}
          helperText={Boolean(errors.confirmacao && touched.confirmacao) && errors.confirmacao}
          label="Confirme a senha"
          placeholder="Confirme a senha digitada!"
        />
        <Button type="submit" startIcon={<CheckCircleIcon />} className={classes.submit}>
          Atualizar
        </Button>
      </form>
    </Grid>
  );
}
