export enum TiposModulo {
  ConfigurarContrato = 0,
  MenuVip = 2,
  PortalS2 = 7,
}

export class Modulo {
  public Nome: string;
  public Modulo: TiposModulo;
  public Credenciais: string;
  public IsAtivo: boolean;
  public IsMultiEmpresa: boolean;
  constructor(modulo: number, credenciais: string) {
    this.Modulo = modulo;
    this.Credenciais = credenciais;
    this.Nome = this.nomeModulo(modulo);
    this.IsAtivo = this.validaModuloAtivo(modulo);
    this.IsMultiEmpresa = this.validaMultiEmpresa(modulo);
  }

  //para pegar o nome por extenso
  private nomeModulo = (modulo) => {
    switch (modulo) {
      case TiposModulo.ConfigurarContrato:
        return 'Configurar Contrato';
      case TiposModulo.MenuVip:
        return 'MenuVIP';
      case TiposModulo.PortalS2:
        return 'S2 Pay';

      default:
        return '';
    }
  };
  //depois isso vai sair, da api já vai vir sem os inativos
  private validaModuloAtivo = (modulo) => {
    return true;
  };

  //talvez saia, por enquanto só o cardápio é multi empresas
  private validaMultiEmpresa = (modulo) => {
    return modulo === TiposModulo.MenuVip;
  };
}
