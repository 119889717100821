import { VariaveisAmbiente } from 'ambiente';
import {
  WebServiceSaurusUtilsProps,
  WebServiceSaurusUtilsInvocarApiProps,
} from './use-webservice-saurus-utils-props';
import { useCallback, useState } from 'react';
import { fetchApiComTimeout } from 'utils';
import X2JS from 'x2js';
import { useNotificacoes } from 'componentes';
import * as pako from 'pako';

// tslint:disable-next-line: cognitive-complexity
export function useWebServiceSaurusUtils<Tipo = any>(props: WebServiceSaurusUtilsProps) {
  const { ignorarErros, operacao, xml, responseTagNome, resultadoTagNome } = props || {};
  const { showWarningMessage } = useNotificacoes();
  const [carregando, setarCarregando] = useState(false);
  const [dados, setarDados] = useState<Tipo | null>(null);

  const hoje = new Date();
  const dia = hoje.getDate();
  const mes = hoje.getMonth() + 1;
  const ano = hoje.getFullYear() - 2000;
  const senhaWebService = btoa(`ophd02ophd02|@${dia + mes + ano}`);

  const invocarApi = useCallback(
    async (invocarProps: WebServiceSaurusUtilsInvocarApiProps) => {
      const {
        operacao: invocarOp,
        xml: invocarXml,
        responseTagNome: invocarResponseTagNome,
        resultadoTagNome: invocarResultadoTagNome,
      } = invocarProps || {};

      setarCarregando(true);
      let resultado: any | undefined;
      let erro: Error | undefined;
      let xmlDados: any;
      let resultadoJson: any;

      const xmlFn = invocarXml || xml;
      const body = xmlFn && xmlFn(senhaWebService);
      const operacaoAtual = invocarOp || operacao;
      const responseTag = invocarResponseTagNome || responseTagNome || `${operacaoAtual}Response`;
      const resultadoTag = invocarResultadoTagNome || resultadoTagNome || `${operacaoAtual}Result`;

      try {
        resultado = await fetchApiComTimeout(
          `${VariaveisAmbiente.saurusUtilsWebService}?op=${operacaoAtual}`,
          10 * 1000 /* 10 Segundos */,
          {
            body: body?.trim(),
            headers: {
              'Content-Type': 'application/soap+xml; charset=utf-8',
              Accept: '*/*',
            },
            method: 'POST',
          },
        );

        const parser = new X2JS();
        const xmlRetorno = resultado?.sucesso ? parser.xml2js<Tipo>(resultado?.text) : null;
        xmlDados = ((xmlRetorno as any)?.Envelope?.Body || {})[responseTag];
        const xmlResultado = pako.ungzip(atob(xmlDados[resultadoTag]), {
          to: 'string',
        });
        resultadoJson = parser.xml2js(xmlResultado);
        setarDados(resultadoJson as Tipo);
      } catch (e) {
        erro = e;
      }

      setarCarregando(false);

      const houveErro =
        resultado.sucesso === false || +xmlDados.xRetNumero !== 0 || Boolean(erro) === true;

      if (houveErro && !ignorarErros) {
        showWarningMessage(xmlDados.xRetTexto || 'Houve um erro ao executar a ação!');
      }

      return { dados: resultadoJson, sucesso: houveErro === false, houveErro };
    },
    [
      senhaWebService,
      xml,
      showWarningMessage,
      resultadoTagNome,
      ignorarErros,
      responseTagNome,
      operacao,
    ],
  );

  return {
    dados,
    carregando,
    invocarApi,
  };
}
