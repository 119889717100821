import React, { useCallback, useEffect } from 'react';
import useState from 'react-usestateref';
import {
  Grid,
  Button,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  Fab,
} from '@material-ui/core';
import { TituloComBorda, useNotificacoes, Carregando, Modal, GridModulo } from 'componentes';
import { useStyles } from './empresa-edit-styles';
import { useFormik } from 'formik';
import { useGetEmpresa, useAtualizarEmpresa, useLoginService } from 'servicos';
import { useHistory, useParams } from 'react-router-dom';
import { GestaoStorageKeys, useGestaoStorage, converterTokenGestao } from 'utils';
import { isEmpty } from 'lodash';

import { Acesso, TokenGestao, Modulo, Empresa } from 'modelos';
import { DadosEmpresaForm } from 'paginas/publico/novo-usuario/componentes';


export function EmpresaEditPagina() {
  const contasLogadas = localStorage.getItem('ContasLogadas')
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();
  const { realizarLogout, carregando, setCarregandoTela } = useLoginService();
  const { showErrorMessage, showSuccessMessage } = useNotificacoes();
  const history = useHistory();
  const { getRegistro, delRegistro, exists } = useGestaoStorage();
  const { getEmpresa } = useGetEmpresa();
  const { putEmpresa } = useAtualizarEmpresa();



  const { handleSubmit } = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      const { message, sucesso } = await realizarLogout();
      if (!sucesso) {
        showErrorMessage('Erro ao realizar o Logout. Detalhe: ' + message);
      } else {
        history.replace('/login');
      }
    },
  });

  const [empresa, setEmpresa] = useState<Empresa>(null);

  //empresas
  const [acesso, setAcesso] = useState(new Acesso('', '', []));
  //token Deserializado
  const [token, setToken] = useState(new TokenGestao('', '', '', '', '', '', [], 0));

  const [carregandoForm, setCarregandoForm] = useState(true);



  //coloquei o useEffect para corrigir o fluxo
  useEffect(() => {
    setAcesso(getRegistro(GestaoStorageKeys.AcessoUsuario));
    setToken(
      converterTokenGestao(getRegistro(GestaoStorageKeys.TokenGestao)) ||
      new TokenGestao('', '', '', '', '', '', [], 0),
    );
  }, [getRegistro]);
  const getEmpresaApi = async () => {
    const result = await getEmpresa(id);
    if (result.statusCode == 200) {
      const empresaModel = new Empresa(result.resultado.data?.id, result.resultado.data?.doc, result.resultado.data?.ie, result.resultado.data?.nome, result.resultado.data?.fant, result.resultado.data?.contato, result.resultado.data?.cep, result.resultado.data?.lgr, result.resultado.data?.nro, result.resultado.data?.cpl, result.resultado.data?.bairro, result.resultado.data?.municipio, result.resultado.data?.uf, result.resultado.data?.pais, result.resultado.data?.codMun, result.resultado.data?.codPais, result.resultado.data?.telefone, result.resultado.data?.email, result.resultado.data?.site, result.resultado.data?.crt, result.resultado.data?.gmt, result.resultado.data?.cnae, result.resultado.data?.indStatus, result.resultado.data?.infAdic, result.resultado.data?.tabelaPrecoId, result.resultado.data?.contratoId);
      setEmpresa(empresaModel);
    } else {
      history.push('/configuracoes')
    }
  }
  useEffect(() => {
    getEmpresaApi();
  }, []);

  const putEmpresaApi = async (empresa: Empresa) => {
    if (empresa != null) {
      if (empresa.Cep != null) {
        empresa.Cep = empresa?.Cep?.replace(/\D/g, '');
      }
      const result = await putEmpresa(empresa);
      if (result != null) {
        if (result.statusCode == 200) {
          showSuccessMessage('Dados atualizados com sucesso');
          if (exists(GestaoStorageKeys.CallbackAcao)) {
            setTimeout(() => {
              let callback = getRegistro(GestaoStorageKeys.CallbackAcao);
              window.location.href = atob(callback).replace('@token', getRegistro(GestaoStorageKeys.TokenGestao));
              limparStorage();
            }, 1000);
          } else {
            history.push('/configuracoes')
          }
        } else {
          showErrorMessage('Erro ao enviar as informações');
        }
      }
    }
  }

  useEffect(() => {
    if (empresa !== null) {
      setCarregandoForm(false)
    }
  }, [empresa])


  //limpa do storage, mesmo estando em sessão é bom limpar
  const limparStorage = () => {
    delRegistro(GestaoStorageKeys.CallbackAcao);
    delRegistro(GestaoStorageKeys.ValidarDominio);
    delRegistro(GestaoStorageKeys.TokenGestao);
    delRegistro(GestaoStorageKeys.TokenSocialFacebook);
    delRegistro(GestaoStorageKeys.TokenSocialGoogle);
    delRegistro(GestaoStorageKeys.AcessoUsuario);
  };



  //volta para o login
  const voltar = () => {
    if (exists(GestaoStorageKeys.CallbackAcao)) {
      let callback = getRegistro(GestaoStorageKeys.CallbackAcao);
      window.location.href = atob(callback).replace('@token', getRegistro(GestaoStorageKeys.TokenGestao));
    } else {
      history.replace('/configuracoes');
    }
  };

  if (carregando.carregando) {
    return (
      <div className={classes.carregando}>
        <Carregando carregando={true} titulo={carregando.titulo} mensagem={carregando.mensagem} />
      </div>
    );
  }


  if (carregandoForm) {
    return (
      <div className={classes.carregando}>
        <Carregando carregando={true} titulo={carregando.titulo} mensagem={carregando.mensagem} />
      </div>
    );
  }





  //tinha vez q dava exception aqui só para previnir
  if (acesso !== null && acesso !== undefined && Object.entries(acesso).length !== 0) {
    if (isEmpty(acesso?.perfis[0]?.empresaId)) {
      return (
        <Grid item container xs={10} md={12} className={classes.container} justify="center">
          <TituloComBorda titulo="Bem Vindo!" />
          <form onSubmit={handleSubmit} className={classes.form}>
            <Button type="submit" className={classes.submit}>
              Deslogar
            </Button>
          </form>
        </Grid>
      );
    }
  }

  //se não houver módulo ativo
  if (token?.modulosObj?.filter((x) => x.IsAtivo).length === 0) {
    showErrorMessage('Erro ao selecionar sistema, motivo: a conta não possui nenhum módulo ativo');
    limparStorage();
    history.replace('/login');
  }


  function montaform() {
    if (empresa != null) {
      return (
        <DadosEmpresaForm
          textoBotao="SALVAR EMPRESA"
          empresa={empresa}
          voltar={voltar}
          avancar={putEmpresaApi}
        />
      );
    } else {
      return null;
    }

  }


  return (
    <>
      <div className={classes.formContainer}>
        <TituloComBorda titulo={'Dados Comerciais'} />
        <Typography variant="body2" align="center">
          Perfeito! Complete agora os dados comerciais da sua empresa
        </Typography>
        <div>
          {montaform()}
        </div>
      </div>
    </>
  );
}
