import React, { FC } from 'react';
import { CircularProgress, Typography, Grid } from '@material-ui/core';
import { CarregandoProps } from './carregando-props';
import { useStyles } from './carregando-styles';

export const Carregando: FC<CarregandoProps> = ({ carregando, mensagem, titulo, telaCheia }) => {
  const classes = useStyles();

  if (carregando === false) {
    return null;
  }

  return (
    <Grid
      className={telaCheia ? classes.containerTelaCheia : undefined}
      container
      direction="column"
      justify="center"
      alignItems="center"
      spacing={0}
    >
      <CircularProgress size={65} className={classes.spinner} />
      {titulo && (
        <Typography variant="h4" align="center">
          <strong>{titulo}</strong>
        </Typography>
      )}
      {mensagem && <Typography variant="body1">{mensagem}</Typography>}
    </Grid>
  );
};
