import { ApiGestaoProps } from './api-gestao.props';
import { VariaveisAmbiente } from 'ambiente';
import { makeUseAxios, Options } from 'axios-hooks';
import { FormatarResposta, TipoRetornoEnum } from './formatar-resposta';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import LRU from 'lru-cache';
import { useCallback } from 'react';
import { merge } from 'lodash';
import { useState } from 'react';
import { converterTokenGestao, GestaoStorageKeys, useGestaoStorage } from 'utils';

type RequestParams = AxiosRequestConfig & {
  enviarTokenUsuario: boolean;
  enviarTokenIntegracao: boolean;
  forcarTokenIntegracao: boolean;
};
const defaultTimeout = 10 * 1000; /* 10 Segundos */
const useAxios = makeUseAxios({
  axios: axios.create({
    baseURL: `${VariaveisAmbiente.apiUrl}/api`,
    timeout: defaultTimeout,
    timeoutErrorMessage: 'O servidor não respondeu a solicitação, tente novamente mais tarde.',
  }),
  cache: new LRU({ max: 10 }),
});

export function useApiGestao<Tipo = any>(props?: ApiGestaoProps) {
  const { getRegistro, exists, delRegistro, setRegistro } = useGestaoStorage();

  const { config, opcoes } = props || {};
  const [{ loading: carregandoAxios }, invocarAxios] = useAxios<Tipo>(
    config || '',
    merge({ manual: true }, opcoes),
  );
  const objRet = { tipoRetorno: TipoRetornoEnum.Sucesso, statusCode: 200 };
  const [carregandoInterno, setCarregandoInterno] = useState(false);

  const invocarApi = useCallback(
    async (cfg?: RequestParams, options?: Options) => {
      let resultado: AxiosResponse<Tipo> | undefined;
      let erro: Error | undefined;
      try {
        let tokenEnvio = cfg?.enviarTokenIntegracao
          ? `Bearer ${(await retornaTokenIntegracao(Boolean(cfg?.forcarTokenIntegracao))).token}`
          : cfg?.enviarTokenUsuario
          ? `Bearer ${getRegistro(GestaoStorageKeys.TokenGestao)}`
          : null;
     
        resultado = await invocarAxios(
          {
            ...cfg,
            headers: {
              ...cfg?.headers,
              Authorization: tokenEnvio,
            },
          },
          options,
        );
        const { tipoRetorno: tpretorno, statusCode: retStatus } = FormatarResposta(
          undefined,
          resultado,
        );
        objRet.statusCode = retStatus;
        objRet.tipoRetorno = tpretorno;
      } catch (e) {
        if (VariaveisAmbiente.isDev) console.log(e);

        erro = e;
        const { tipoRetorno: tpretorno, statusCode: retStatus } = FormatarResposta(e, e.response);
        objRet.statusCode = retStatus;
        objRet.tipoRetorno = tpretorno;

        //TRATAMENTO DE RRO DE API
        if (e.response && e.response.data.title) {
          erro = new Error(e.response.data.title);
        }

        switch (objRet.tipoRetorno) {
          case TipoRetornoEnum.Local:
            let msg = erro?.message.toString() || '';
            if (msg.indexOf('timeout') > -1 && msg.indexOf('exceeded') > -1) {
              erro = new Error(
                `O servidor demorou muito para processar a requisição (${
                  (cfg?.timeout || defaultTimeout) / 1000
                }s).`,
              );
            } else {
              erro = new Error(
                `A comunicação com o servidor foi perdida. Tente novamente em alguns instantes. Detalhe: ${erro?.message}`,
              );
            }
            break;
          case TipoRetornoEnum.Api:
            if (retStatus === 401) {
              erro = new Error(`Suas credenciais não foram aceitas pelo Servidor. (401)`);
            }
            break;
          case TipoRetornoEnum.Servidor:
            erro = new Error(
              `O procedimento solicitado causou um erro no servidor. Tente novamente em alguns instantes. Detalhe: ${erro?.message}`,
            );
            break;
        }
      }
      setCarregandoInterno(false);
      return { resultado, erro, tipoRetorno: objRet.tipoRetorno, statusCode: objRet.statusCode };
    },
    // eslint-disable-next-line
    [invocarAxios, getRegistro, objRet.statusCode, objRet.tipoRetorno],
  );

  const retornaTokenIntegracao = useCallback(
    async (forcar: boolean) => {
      //SE TENHO O TOKEN, VALIDO E USO ELE;
      if (exists(GestaoStorageKeys.TokenIntegracao)) {
        const tok = getRegistro(GestaoStorageKeys.TokenIntegracao);

        let jwt = converterTokenGestao(tok || '');

        if (!jwt) {
          delRegistro(GestaoStorageKeys.TokenIntegracao);
        } else if (jwt.exp * 1000 < new Date().getTime() || forcar) {
          delRegistro(GestaoStorageKeys.TokenIntegracao);
        } else {
          return jwt;
        }
      }

      //SE ELE NAO EXISTE OU FOI REMOVIDO ACIMA, VAMOS NA API CONSULTAR
      if (!exists(GestaoStorageKeys.TokenIntegracao)) {
        //VOU NA API BUSCAR O TOKEN
        const { resultado, erro } = await invocarApi({
          url: `token/integradores/${btoa('s@urusIntegradores')}`,
          method: 'GET',
        });

        if (erro) return null;

        if (resultado?.data.token != null)
          setRegistro(GestaoStorageKeys.TokenIntegracao, resultado?.data.token);

        return converterTokenGestao(resultado?.data.token || '');
      }
      return null;
    },
    [invocarApi, delRegistro, exists, getRegistro, setRegistro],
  );

  return {
    invocarApi,
    carregando: carregandoAxios || carregandoInterno,
    setCarregando: carregandoInterno ? setCarregandoInterno : null,
  };
}
