import { SiteValido } from 'validacao';
import * as Yup from 'yup';

export const DadosEmpresaFormValidacao = Yup.object().shape({
  Nome: Yup.string().required('A razão social é obrigatória.'),
  Telefone: Yup.string().nullable().optional().min(10, 'Telefone inválido.'),
  Site: Yup.string().nullable().optional().test('site', 'Site inválido.', SiteValido),
  Cep: Yup.string().required('CEP inválido.').test('len', 'CEP inválido.', (val) => {
    const val_length_withoutdashes = val?.replace(/-|/g, '').length;
    return val_length_withoutdashes === 8;
  }),
  Lgr: Yup.string().required('O endereço é obrigatório.'),
  Nro: Yup.string().required('Número obrigatório.'),
  Bairro: Yup.string().required('O bairro obrigatório.'),
  Municipio: Yup.string().required('O município obrigatório.'),
  UF: Yup.string().required('UF inválida.'),
});


