import React, { Fragment } from 'react';
import { Typography, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { TextoELinkProps } from './texto-e-link-props';

export const TextoELink = ({
  descricao,
  linkDescricao,
  align,
  variant,
  para,
  juncao,
}: TextoELinkProps) => {
  juncao = juncao || 'quebrar';

  return (
    <Typography variant={variant || 'body2'} align={align || 'center'}>
      {descricao}
      
      {para && linkDescricao && (
        <Fragment>
          {juncao === 'quebrar' && <br />}
          {juncao === 'espacar' && ' '}
          <Link component={RouterLink} to={para} color="primary">
            <strong>{linkDescricao}</strong>
          </Link>
        </Fragment>
      
      )}
    </Typography>
  );
};
