import { useCallback } from 'react';
import { useApiGestao } from 'servicos/api-gestao/base';

export function useGetTokenUsuario() {
  const { invocarApi, ...outrasPropriedades } = useApiGestao();

  const getToken = useCallback(
    async (email: string, senha: string) => {
      const ret = await invocarApi({
        url: `token/${btoa(email)}/${btoa(senha)}`,
        enviarTokenIntegracao: true
      });

      let retError = {};
      //ERRO NO TOKEN
      if (ret.statusCode === 401) {
        retError = await invocarApi({
          url: `token/${btoa(email)}/${btoa(senha)}`,
          enviarTokenIntegracao: true,
          forcarTokenIntegracao: true
        });
      }
      return { ...ret, ...retError };
    },
    [invocarApi],
  );

  return {
    ...outrasPropriedades,
    getToken,
  };
}
