import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
  espacarDireitaInput: {
    paddingRight: '0px',
    [theme.breakpoints.up('sm')]: {
      paddingRight: '10px',
    },
  },
  marginDireita: {
    marginRight: '10px',
  },
  acoesContainer: {
    marginTop: '10px',
  },
}));
