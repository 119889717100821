import React from 'react';
import { useStyles } from './card-icon-styles';
import { CardIconProps } from './card-icon-props';
import { Card, CardActionArea, CardContent, SvgIcon, Typography } from '@material-ui/core';
import { TiposModulo } from 'modelos';

import logoS2Pay from 'arquivos-estaticos/logos/logo-s2Pay.png';
import logoSaurus from 'arquivos-estaticos/logos/logo-saurus.png';
import logoMenuVip from 'arquivos-estaticos/logos/logo-menuVip.png';
import WarningIcon from '@material-ui/icons/Warning';
import react from 'react';



export const CardIcon = ({texto, clickFunc, icon }: CardIconProps) => {
  const renderIcon = () => {
    if(typeof icon === 'string'){
      return(<img src={icon} alt={texto} className={classes.imgLogo}></img>);
    } else if(icon.muiName === 'SvgIcon') {
      let typeofIcon = typeof icon;
      return(<SvgIcon style={{ fontSize: 80 }} component={icon}></SvgIcon>);
    } else{
        console.log('Valor da propriedade "Icon" é inválido')
    }
  }
  const classes = useStyles();

  return (
    <>
      <Card className={classes.cardModulo}>
        <CardActionArea>
          <CardContent
            className={`${classes.cardModulo} ${classes.cardContent}`}
            onClick={clickFunc}
          >
            <div className={classes.cardItem}>
              {renderIcon()}
              <div className={classes.textContainer}>
                <Typography
                  variant="inherit"
                  component="div"
                  className={classes.heading}
                  gutterBottom
                >
                  {texto}
                </Typography>
              </div>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};
