import { useCallback } from 'react';
import { useApiGestao } from 'servicos/api-gestao/base';

export function useGetAcesso() {
  const { invocarApi, ...outrasPropriedades } = useApiGestao();

  const getAcesso = useCallback(
    () =>
      invocarApi({
        url: `acesso`,
        enviarTokenUsuario: true
      }),
    [invocarApi],
  );

  return {
    ...outrasPropriedades,
    getAcesso,
  };
}
