import React, { Props } from 'react';
import { SnackbarProvider } from 'notistack';
import { Button } from '@material-ui/core';

const notistackRef = React.createRef<any>();

const fecharNotificao = (key: any) => () => {
  notistackRef.current.closeSnackbar(key);
};

const fecharNotificacaoComponent = (key: any) => (
  <Button onClick={fecharNotificao(key)} variant="text" color="inherit" size="small">
    <strong>Fechar</strong>
  </Button>
);

export function Notificacoes({ children }: Props<any>) {
  return (
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={5}
      preventDuplicate
      action={fecharNotificacaoComponent}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      {children}
    </SnackbarProvider>
  );
}
