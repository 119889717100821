import { GestaoStorageKeys, useGestaoStorage } from 'utils';

export enum TiposLogin {
  Manual,
  Google,
  Facebook,
}

export function UserAccounts() {
  const { setRegistro, getRegistro } = useGestaoStorage();

  const setUser = (email:any, token: any,nome:any, tipo: TiposLogin) => {
    let oldUsers = getRegistro(GestaoStorageKeys.ContasLogadas, false);
    let array;

    if (Object.entries(oldUsers).length === 0) {
      array = []; //caso seja a primeira vez que passa no storage
    } else {
      array = oldUsers;
    }

    let index = array.length > 0 ? oldUsers.findIndex((val) => val.email === email) : -1;

    if (index < 0) {
      array.push({
        id: generateUUID(),
        email: email,
        nome:nome,
        token: token,
        dateLogged: new Date().toJSON(),
        tipo: tipo,
      });
    } else {
      //estou alterando algumas propriedades a mais pois se forem alteradas ele altera no storage também
      array[index].dateLogged = new Date().toJSON();
      array[index].tipo=tipo;
      array[index].token=token;
      array[index].nome=nome;
    }

    setRegistro(GestaoStorageKeys.ContasLogadas, array, false);
  };

  const getLoggedUsers = () => {
    let registros = getRegistro(GestaoStorageKeys.ContasLogadas, false);
    let array;
    if (Object.entries(registros).length === 0) {
      array = []; //caso seja a primeira vez que passa no storage
    } else {
      array = registros;
    }
    
    return array.sort((firstEl, secondEl) => {
      return  secondEl.dateLogged.localeCompare(firstEl.dateLogged);
    });
  }; 
  
  const deleteUser = (id) => {
    let registros = getRegistro(GestaoStorageKeys.ContasLogadas, false);
    let array;
    if (Object.entries(registros).length === 0) {
      array = []; //caso seja a primeira vez que passa no storage
    } else {
      array = registros;
    }

    let filtered = array.filter(x=>x.id!==id);
    setRegistro(GestaoStorageKeys.ContasLogadas, filtered, false);

  };

  const generateUUID = () => {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid.toString();
  };

  return {
    getLoggedUsers,
    setUser,
    deleteUser,
  };
}
