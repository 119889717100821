import { makeStyles, fade } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    top: 0,
    right: '50%',
    marginRight: '-225px',
    width: '450px',
    height: '100%',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      margin: '0px',
      right: '0px',
      width: '100%',
    },
  },
  bolha: {
    position: 'absolute',
    willChange: 'transform',
    right: '5%',
    border: '30px solid rgba(0, 0, 0, 0.05)',
    borderColor: fade(theme.palette.primary.main, 0.05),
    borderRadius: '900px',
    height: '400px',
    width: '400px',
    [theme.breakpoints.up('sm')]: {
      borderColor: fade(theme.palette.primary.dark, 0.1),
    },
  },
  bolha0: {
    top: '5%',
  },
  bolha1: {
    top: '30%',
  },
  bolha2: {
    top: '50%',
  },
  bolha3: {
    top: '90%',
  },
}));
