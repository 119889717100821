import React from 'react';
import { animated, config, useSprings } from 'react-spring';
import { useStyles } from './bolhas-styles';
import classNames from 'classnames';

const animations = [
  {
    translateFrom: 0,
    translateTo: -70,
    timeMs: 10 * 1000,
    top: '5%',
  },
  {
    translateFrom: 0,
    translateTo: -80,
    timeMs: 18 * 1000,
    top: '30%',
  },
  {
    translateFrom: -10,
    translateTo: 0,
    timeMs: 15 * 1000,
    top: '50%',
  },
  {
    translateFrom: -180,
    translateTo: -90,
    timeMs: 12 * 1000,
    top: '90%',
  },
];

export function Bolhas() {
  const classes = useStyles();
  const springs = useSprings(
    animations.length,
    animations.map((current) => ({
      from: {
        transform: `translateY(${current.translateFrom}px)`,
      },
      to: {
        transform: `translateY(${current.translateTo}px)`,
      },
      loop: {
        reverse: true,
      },
      config: {
        ...config.default,
        duration: current.timeMs,
      },
    })),
  );

  return (
    <div className={classes.container}>
      {springs.map((props, index) => (
        <animated.span
          key={index}
          className={classNames(classes.bolha, classes[`bolha${index}`])}
          style={props}
        />
      ))}
    </div>
  );
}
