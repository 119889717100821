export const files = () => {
  const loadFile = (environment, filePath, fileName) => {
    try {
      const fileImported = require(`./${environment}/${filePath}${fileName}`);
      return fileImported;
    } catch {
      return '';
    }
  };

  const loadImagem = (environment, fileName) => {
    return  loadFile(environment, 'imagens/', fileName);
  };
  const loadLogo = (environment, fileName) => {
    return loadFile(environment, 'logos/', fileName);
  };

  // Remover ao implementar o upload de gravatar - Vinicius Serafim 28-06-2021
  const loadGravatar = (environment, fileName) => {
    return loadFile(environment, 'gravatar/', fileName);
  };

   const loadFavIcon = (environment, fileName) => {
    return loadFile(environment, 'icons/', fileName);
  };

  return {
    loadImagem,
    loadLogo,
    loadGravatar,
    loadFavIcon
  };
};
