import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useStyles } from './dados-empresa-form-styles';
import { TextField, Grid, Button } from '@material-ui/core';
import { CepInput, CnpjInput, InputComMascara, TelefoneInput, UfSelect } from 'componentes';
import { DadosEmpresaFormProps } from './dados-empresa-form-props';
import { useFormik } from 'formik';
import { DadosEmpresaFormValidacao } from './dados-empresa-form-validacao';
import { ArrowBackIos, CheckCircle } from '@material-ui/icons';
import { Empresa, TipoForm } from 'modelos';

export const DadosEmpresaForm: React.FC<DadosEmpresaFormProps> = ({ avancar, voltar, empresa, textoBotao, tipo }) => {
  const classes = useStyles();
  const inputNumeroRef = useRef<HTMLDivElement | null>(null);
  const [dadosConsultaCNPJ, setDadosConsultaCNPJ] = useState<any>(null);
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      Nome: '',
      Fant: '',
      Telefone: '',
      Site: '',
      Cep: '',
      Lgr: '',
      Nro: '',
      Cpl: '',
      Bairro: '',
      Municipio: '',
      UF: '',
      Doc: '',
      Cnae: 0,
      Crt: 1,
      CodMun: 0,
      Ie: '',
      ...empresa,
    },
    validationSchema: DadosEmpresaFormValidacao,
    onSubmit: (dados) => {
      avancar(dados)
    }, 
  });
  const quandoEncontrarUmCepExecutar = useCallback(
    (dados: any) => {
      setFieldValue('Lgr', dados.consultaCEP.Logradouro, true);
      setFieldValue('Bairro', dados.consultaCEP.Bairro, true);
      setFieldValue('Municipio', dados.consultaCEP.Municipio, true);
      setFieldValue('UF', dados.consultaCEP.UF, true);
      inputNumeroRef?.current?.focus();
    },
    [setFieldValue, inputNumeroRef],
  );


  const cnpjEditavel = tipo === TipoForm.POST ? false : true; // Determina se o campo CNPJ será editavel ou não; Vinicius Serafim - 02/07/2021

  const [cepValue, setCepValue] = useState<string>(values.Cep);

  useEffect(() =>{
    setFieldValue('Cep', cepValue);
  },
  [cepValue]);


  const quandoEncontrarUmCnpjExecutar = useCallback(
    (dados: any) => {
      console.log(dados);
      setFieldValue('Fant', dados._Fant);
      setFieldValue('Telefone', dados._Fone);
      setFieldValue('Nome', dados._xNome);
      setFieldValue('Lgr', dados._xLgr);
      setFieldValue('Nro', dados._nro);
      setFieldValue('Cpl', dados._xCpl);
      setFieldValue('Bairro', dados._xBairro)
      setFieldValue('Municipio', dados._xMun);
      setFieldValue('UF', dados._UF, true);
      setFieldValue('Cnae', dados._CNAE);

      setFieldValue('Crt', dados._RegApuracao ===
      'SIMPLES NACIONAL'
        ? 1
        : 3)

      setFieldValue('CodMun', dados._cMun);

      setFieldValue('Ie', dados._IE);
      
      setCepValue(dados._CEP);

      
      setDadosConsultaCNPJ(dados);
    },
    [cepValue],
  );

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={5} className={classes.espacarDireitaInput}>
          <CnpjInput
            UF={values.UF}
            hasButton={true}
            disabled={cnpjEditavel}
            label="CNPJ"
            placeholder="CNPJ"
            name="Doc"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.Doc}
            error={Boolean(errors.Doc && touched.Doc)}
            helperText={errors.Doc && touched.Doc ? errors.Doc : undefined}
            quandoEncontrarUmCnpjExecutar={quandoEncontrarUmCnpjExecutar}
          />
        </Grid>
        <Grid item xs={12} sm={7}>
          <TextField
            label="Razão Social"
            placeholder="Razão Social"
            name="Nome"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.Nome}
            error={Boolean(errors.Nome && touched.Nome)}
            helperText={errors.Nome && touched.Nome ? errors.Nome : undefined}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Nome Fantasia"
            placeholder="Nome Fantasia"
            name="Fant"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.Fant}
            error={Boolean(errors.Fant && touched.Fant)}
            helperText={errors.Fant && touched.Fant ? errors.Fant : undefined}
          />
        </Grid>
        <Grid item xs={12} sm={5} className={classes.espacarDireitaInput}>
          <TelefoneInput
            label="Telefone"
            placeholder="DDD + Telefone"
            name="Telefone"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.Telefone}
            error={Boolean(errors.Telefone && touched.Telefone)}
            helperText={errors.Telefone && touched.Telefone ? errors.Telefone : undefined}
          />
        </Grid>
        <Grid item xs={12} sm={7}>
          <TextField
            label="Site"
            placeholder="Site"
            name="Site"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.Site}
            error={Boolean(errors.Site && touched.Site)}
            helperText={errors.Site && touched.Site ? errors.Site : undefined}
          />
        </Grid>
        <Grid item xs={12} sm={4} className={classes.espacarDireitaInput}>
          <CepInput
            label="CEP"
            placeholder="CEP"
            name="Cep"
            onChange={handleChange}
            onBlur={handleBlur}
            value={cepValue}
            quandoEncontrarUmCepExecutar={quandoEncontrarUmCepExecutar}
            error={Boolean(errors.Cep && touched.Cep)}
            helperText={errors.Cep && touched.Cep ? errors.Cep : undefined}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            label="Endereço"
            placeholder="Endereço"
            name="Lgr"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.Lgr}
            error={Boolean(errors.Lgr && touched.Lgr)}
            helperText={errors.Lgr && touched.Lgr ? errors.Lgr : undefined}
          />
        </Grid>
        <Grid item xs={12} sm={3} className={classes.espacarDireitaInput}>
          <TextField
            inputRef={inputNumeroRef}
            label="Número"
            placeholder="Numero"
            name="Nro"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.Nro}
            error={Boolean(errors.Nro && touched.Nro)}
            helperText={errors.Nro && touched.Nro ? errors.Nro : undefined}
          />
        </Grid>
        <Grid item xs={12} sm={5} className={classes.espacarDireitaInput}>
          <TextField
            label="Complemento"
            placeholder="Complemento"
            name="Cpl"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.Cpl}
            error={Boolean(errors.Cpl && touched.Cpl)}
            helperText={errors.Cpl && touched.Cpl ? errors.Cpl : undefined}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Bairro"
            placeholder="Bairro"
            name="Bairro"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.Bairro}
            error={Boolean(errors.Bairro && touched.Bairro)}
            helperText={errors.Bairro && touched.Bairro ? errors.Bairro : undefined}
          />
        </Grid>
        <Grid item xs={12} sm={8} className={classes.espacarDireitaInput}>
          <TextField
            label="Municipio"
            placeholder="Municipio"
            name="Municipio"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.Municipio}
            error={Boolean(errors.Municipio && touched.Municipio)}
            helperText={errors.Municipio && touched.Municipio ? errors.Municipio : undefined}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <UfSelect
            {...
            {
              label: 'UF',
              placeholder: 'UF',
              name: 'UF',
              error: Boolean(errors.UF && touched.UF),
              helperText: Boolean(errors.UF && touched.UF) ? errors.UF : undefined,
            }}
            onBlur={handleBlur}
            ufValue={values.UF}
            onInputChange={(event, newValue) => {
              if (event) {
                setFieldValue('UF', newValue, true);
              }
            }}
          />
        </Grid>
        <Grid container className={classes.acoesContainer} wrap="nowrap">
          <Button
            onClick={voltar}
            color="secondary"
            className={classes.marginDireita}
            startIcon={<ArrowBackIos />}
            type="button"
          >
            Voltar
          </Button>
          <Button color="primary" type="submit" startIcon={<CheckCircle />}>{textoBotao}</Button>
        </Grid>
      </Grid>
    </form>
  );
};
