import { AmbientePadrao } from 'ambiente';
import { saurusGestao, menuVip, s2Pay } from './res';

const generateObjectRes = () => {
  switch (AmbientePadrao.toString()) {
    case 'menuVip':
      return menuVip.getObject();
    case 'saurusGestao':
      return saurusGestao.getObject();
    case 's2Pay':
      return s2Pay.getObject();
    default:
      return saurusGestao.getObject();
  }
};

const res = generateObjectRes();

export const useResources = () => {
  return res;
};
