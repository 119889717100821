import React from 'react';
import { Typography, Button, Grid } from '@material-ui/core';
import { NotificacaoEstaticaProps } from './notificacao-estatica-props';
import { useStyles } from './notificacao-estatica-styles';

export const NotificacaoEstatica: React.FC<NotificacaoEstaticaProps> = ({
  acao,
  acaoTexto,
  acaoIcone,
  children,
  mensagem,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.icone}>{children}</div>
      <Typography variant="body2" align="center">
        {mensagem}
      </Typography>
      <Grid xs={6} style={{marginTop:20}}>
        <Button type="button" startIcon={acaoIcone} onClick={acao}>
          {acaoTexto}
        </Button>
      </Grid>
    </div>
  );
};
