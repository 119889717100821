import { useCallback } from 'react';
import { useApiGestao } from 'servicos/api-gestao/base';

export function useGetTabelaPreco() {
  const { invocarApi, ...outrasPropriedades } = useApiGestao();

  const getTabelasPreco = useCallback(
    () =>
      invocarApi({
        url: `tabelaPreco`,
        enviarTokenUsuario: true
      }),
    [invocarApi],
  );

  return {
    ...outrasPropriedades,
    getTabelasPreco
  };
}
