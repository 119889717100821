import { useCallback, useState } from "react";
import { useGerarPreContrato, useCadastrarContrato } from 'servicos/api-gestao/contrato';

export function useContratoService() {

    const [carregandoTela, setCarregandoTela] = useState({ carregando: false, mensagem: '', titulo: '' });
    const { gerarPreContrato } = useGerarPreContrato();
    const { cadastrarContrato } = useCadastrarContrato();


    const retornarPreContrato = useCallback(
        async (dominio: string) => {
            try {
                setCarregandoTela({ carregando: true, titulo: 'Buscando Pré-cadastros', mensagem: `Aguarde enquantos realizamos a consulta com o Domínio "${dominio}" informado.` });

                const { erro, resultado, statusCode } = await gerarPreContrato(dominio);
                if (statusCode !== 410 && erro) {
                    throw (erro);
                }

                return { message: 'Consulta realizada com Sucesso.', sucesso: true, contrato: resultado?.data }
            } catch (e) {
                return { message: e.message, sucesso: false, contrato: null };
            } finally {
                setCarregandoTela({ carregando: false, titulo: '', mensagem: '' });
            }
        }, [gerarPreContrato]);


    const gerarContrato = useCallback(
        async (contratoModel: any) => {
            try {
               
                setCarregandoTela({ carregando: true, titulo: 'Finalizando cadastro', mensagem: `Aguarde enquanto realizamos seu cadastro...` });

                const { erro, resultado } = await cadastrarContrato(contratoModel);
                if (erro)
                    throw (erro);

                return { message: 'Cadastro realizado com Sucesso!', contrato: resultado?.data }
            } catch (e) {
                return { message: e.message, contrato: null };
            } finally {
                setCarregandoTela({ carregando: false, titulo: '', mensagem: '' });
            }
        }, [cadastrarContrato]);

    return {
        carregandoTela,
        gerarContrato,
        retornarPreContrato
    };
}