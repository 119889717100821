import React from 'react';
import { Grid, TextField, Button } from '@material-ui/core';
import { TituloComBorda, TextoELink, useNotificacoes, Carregando } from 'componentes';
import { useStyles } from './esqueci-a-senha-styles';
import { useFormik } from 'formik';
import { EsqueciASenhaValidacao } from './esqueci-a-senha-validacao';
import { useLoginService } from 'servicos';
import { useHistory } from 'react-router-dom';
import EmailIcon from '@material-ui/icons/Email';

export function EsqueciASenhaPagina() {
  const classes = useStyles();
  const { recuperarSenha, carregando } = useLoginService();
  const { showSuccessMessage, showErrorMessage } = useNotificacoes();
  const history = useHistory();
  const { handleChange, handleBlur, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: EsqueciASenhaValidacao,
    onSubmit: async (values) => {
      const { message, sucesso } = await recuperarSenha(values.email);
      if (!sucesso) {
        showErrorMessage('Erro ao redefinir sua senha. Detalhe: ' + message);
      } else {
        showSuccessMessage('Um e-mail foi enviado para realizar a alteração da senha. Acesse-o para continuar o processo.');
        history.replace('/login');
      }
    },
  });

  if (carregando.carregando) {
    return (
      <Carregando
        carregando={true}
        titulo={carregando.titulo}
        mensagem={carregando.mensagem}
      />
    );
  }

  return (
    <Grid container xs={10} md={12} className={classes.container} justify="center">
      <TituloComBorda titulo="Recuperar Senha" />
      <TextoELink
        descricao="Informe seu email abaixo para redefinição da sua senha."
        linkDescricao="Voltar ao Login"
        juncao="espacar"
        para="/login"
      />
      <form onSubmit={handleSubmit} className={classes.form}>
        <TextField
          label="E-mail"
          name="email"
          placeholder="usuario@empresa.com.br"
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(errors.email && touched.email)}
          helperText={errors.email && touched.email ? errors.email : undefined}
          autoFocus
        />
        <Button type="submit" startIcon={<EmailIcon />} className={classes.submit}>
          Enviar E-mail de Recuperação
        </Button>
      </form>
    </Grid>
  );
}
