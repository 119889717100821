import { Grid } from '@material-ui/core';
import React, { FC } from 'react';
import { useStyles } from './autenticacao-styles';
import { Bolhas } from './componentes';
import { useResources } from 'resources/resources';

const resources = useResources();


export const AutenticacaoLayout: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.pnlAcesso}>
      <Bolhas />
      <div className={classes.pnlConteudo}>
        <Grid container className={classes.full}>
          <Grid container item xs={12} lg={2} justify="center">
            <Grid item xs={8} md={5} lg={10}>
              <img className={classes.imgLogo} src={resources.logo} alt="Logo Saurus" />
            </Grid>
          </Grid>
          <Grid container item xs={12} lg={8} justify="center" alignItems="center">
            {children}
          </Grid>
          <Grid item xs={12} lg={2} />
        </Grid>
      </div>
      <footer className={classes.pnlFooter}>
        {resources.footer}
      </footer>
    </div>
  );
};
