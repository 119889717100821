import React, { ReactNode } from 'react';
import { TextoELink } from 'componentes';
import { Typography } from '@material-ui/core';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import ApartmentRoundedIcon from '@material-ui/icons/ApartmentRounded';
import { ThumbUpAlt } from '@material-ui/icons';

export interface Step {
  id: StepsEnum;
  titulo: string;
  ComponentHeader: any;
  label: string;
  icone: ReactNode;
  index: number;
}

export enum StepsEnum {
  Inicial = 'inicial',
  CadastrarUsuario = 'cadastrar-usuario',
  ConsultarEmpresa = 'consultar-empresa',
  PedirConvite = 'pedir-convite',
  CadastrarEmpresa = 'cadastrar-empresa',
  Sucesso = 'sucesso',
}

export const Steps: { [key: string]: Step } = {
  Inicial: {
    id: StepsEnum.Inicial,
    index: 0,
    label: '',
    titulo: '',
    icone: null,
    ComponentHeader: null,
  },
  CadastrarUsuario: {
    id: StepsEnum.CadastrarUsuario,
    index: 0,
    label: 'Credenciais',
    titulo: 'Suas Credenciais',
    icone: <PersonRoundedIcon />,
    ComponentHeader: (props: any) => (
      <TextoELink
        descricao="Para ingressar, preencha os campos abaixo para iniciarmos seu cadastro. São apenas três passos para finalizarmos seu cadastro."
        linkDescricao=""
        juncao="espacar"
        para=""
      />
    ),
  },
  ConsultarEmpresa: {
    id: StepsEnum.ConsultarEmpresa,
    index: 1,
    label: 'Empresa',
    titulo: 'Identificar Empresa',
    icone: <SearchRoundedIcon />,
    ComponentHeader: (props: any) => (
      <Typography variant="body2" align="center">
        Agora, informe os dados da sua empresa para podermos personalizar seu ambiente corretamente.
      </Typography>
    ),
  },
  CadastrarEmpresa: {
    id: StepsEnum.CadastrarEmpresa,
    index: 2,
    label: 'Comercial',
    titulo: 'Dados Comerciais',
    icone: <ApartmentRoundedIcon />,
    ComponentHeader: ({ empresa }: any) => (
      <Typography variant="body2" align="center">
        Perfeito! Complete agora os dados comerciais da sua empresa
      </Typography>
    ),
  },
  Sucesso: {
    id: StepsEnum.Sucesso,
    index: 3,
    label: 'Bem Vindo',
    titulo: 'Sucesso!',
    icone: <ThumbUpAlt />,
    ComponentHeader: null,
  },
  PedirConvite: {
    id: StepsEnum.PedirConvite,
    index: 1,
    label: '',
    titulo: 'CNPJ em uso!',
    icone: null,
    ComponentHeader: null,
  },
};

export const StepsVisiveis = [
  Steps.CadastrarUsuario,
  Steps.ConsultarEmpresa,
  Steps.CadastrarEmpresa,
  Steps.Sucesso,
];

export const NovoUsuarioInicial = {
  dominio: '',
  usuario: {
    nome: '',
    email: '',
    senha: '',
    userFacebook: '',
    userGoogle: '',
    facebookToken: '',
    googleToken: '',
  },
  empresa: {
    Doc: '',
    IE: '',
    Nome: '',
    Fant: '',
    Contato: '',
    Cep: '',
    Lgr: '',
    Nro: '',
    Cpl: '',
    Bairro: '',
    Municipio: '',
    UF: '',
    Pais: 'Brasil',
    CodMun: 0,
    CodPai: 1058,
    Telefone: '',
    Email: '',
    Site: '',
    CRT: 1,
    GMT: 3,
    CNAE: 0,
    IndStatus: 0,
    InfAdic: '',
  },
};
