import { useCallback } from 'react';
import { useApiGestao } from 'servicos/api-gestao/base';

export function useAtualizarEmpresa() {
  const { invocarApi, ...outrasPropriedades } = useApiGestao({
    config: {
      url: 'empresa',
      method: 'PUT',
    },
  });

  const putEmpresa = useCallback(
    (dados: any) => {
      const dadosNormalizados = {
        ...dados,
      };

      return invocarApi({
        data: dadosNormalizados,
        enviarTokenIntegracao: true,
        forcarTokenIntegracao: true
      });
    },
    [invocarApi],
  );

  return {
    ...outrasPropriedades,
    putEmpresa,
  };
}
